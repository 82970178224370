/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

export const util = {
	mergeJson : mergeJson
}

function mergeJson(source, target) {
	
	for(var key in target) {
		if(source.hasOwnProperty(key)) {
			var d = source[key];
			var td = target[key];

			if(typeof d == "object" && typeof td == "object") {
				if(Array.isArray(d)){
					if(Array.isArray(td)) {
						d = d.concat(td);
					} else {
						d.push(td);
					}
				}else{
					d = mergeJson(d, td);
				}
			}  else {
				source[key] = target[key];
			}
			
		} else {
			source[key] = target[key];
		}
	}

	return source;
}
