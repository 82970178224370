/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as d3 from "d3"

export const TunnelMap = function(mapcontainerId) {
	
	mapcontainerId = mapcontainerId || "tunnelMap";

	var tunnelMap = {
		mapcontainerId : mapcontainerId,
		svg : null,
		tunnelPadding : 4,
		tunnelStrokeWidth : 2,
		innerPadding : 3,
		tunnelData : [],
		isShow : false,
		init : function() {
			var me = this;

			var mapContainer = d3.select("#"+mapcontainerId);
			var svg = mapContainer.append("svg").attr("width",0).attr("height",0);

			me.svg = svg;

			var defs = svg.append('defs');

			defs.append('pattern')
			.attr('id','hatch')
			// .attr('patternUnits','userSpaceOnUse')
			.attr('patternTransform','rotate(135)')
			.attr('width',4)
			.attr('height',4)
			.append('rect')
			.attr('width',2)
			.attr('height',4)
			// .attr("fill","#292C3E");

			defs.append('pattern')
			.attr('id','selectHatch')
			// .attr('patternUnits','userSpaceOnUse')
			.attr('patternTransform','rotate(135)')
			.attr('width',4)
			.attr('height',4)
			.append('rect')
			.attr('width',2)
			.attr('height',4)
			.attr("fill","#E64359");

			defs.append('pattern')
			.attr('id','hoverHatch')
			// .attr('patternUnits','userSpaceOnUse')
			.attr('patternTransform','rotate(135)')
			.attr('width',4)
			.attr('height',4)
			.append('rect')
			.attr('width',2)
			.attr('height',4)
			.attr("fill","#292C3E");
		},
		draw : function() {
			if(this.svg == null) return;

			this.isShow = true;

			var me = this;
			var svg = me.svg;
			var mapContainer = d3.select("#"+me.mapcontainerId);
			var parentNode = mapContainer.node().parentNode;

			var width = (parentNode.clientWidth);
			var height = (parentNode.clientHeight) - (parseInt(getComputedStyle(parentNode).paddingTop) * 2);

			// var tunnelPadding = me.tunnelPadding;
			var tunnelPadding = me.tunnelPadding/2;
			var tunnelStrokeWidth = me.tunnelStrokeWidth;
			//tunnelHeight 원본 
			//var tunnelHeight = (height - (tunnelPadding + tunnelStrokeWidth));
			var tunnelHeight = (height - (tunnelPadding + tunnelStrokeWidth)) * 2;
			var totalWidth = width - (tunnelStrokeWidth*2);
			var tunnelX = tunnelStrokeWidth;
			var tunnelY = tunnelStrokeWidth;
			var innerPadding = me.innerPadding;

			var flexTotal = 0;
			var tunnelData = me.tunnelData;

			for(var i = 0; i < tunnelData.length; i++) {
				flexTotal += tunnelData[i].flex;
			}

			svg.attr("width", width)
			//height, viewBox 원본
			// .attr("height", height)
			// .attr("viewBox", [0,0,width,height]);
			.attr("height", height*2)
			.attr("viewBox", [0,0,width,height*2]);

			svg.append('rect')
			.attr('id','tunnelMapBox')
			.attr('x', tunnelX)
			.attr('y', tunnelY)
			.attr('width', totalWidth)
			.attr('height', tunnelHeight)
			.attr('stroke', '#2E364B')
			.attr('stroke-width', tunnelStrokeWidth)
			.attr('fill', 'none');

			var divLineGroup = svg.append("g").attr("class","lineGroup");
			var dlX = tunnelX + (totalWidth * (tunnelData[0].flex / flexTotal));
			var lineFunc = d3.line()
			.x(function(d) { return d.x })
			.y(function(d) { return d.y });

			for(var i = 1; i < tunnelData.length; i++) {
				var tunnelWidth = totalWidth * (tunnelData[i].flex / flexTotal);
				//dl 원본
				// var dl = [{x: dlX, y: tunnelY-20},{x: dlX, y: tunnelY+tunnelHeight+20}]
				var dl = [{x: dlX, y: tunnelY},{x: dlX, y: tunnelY+tunnelHeight}]

				divLineGroup.append('path')
				.attr("class","line"+i)
				.attr('d', lineFunc(dl))
				.attr('stroke', 'white')
				.attr('stroke-dasharray',"4,2")
				.attr('fill', 'none');

				dlX += tunnelWidth;
				
			}
			
			var tunnelGroup = svg.append("g").attr("class","tunnelGroup");
			var tunnels = tunnelGroup.selectAll("g").data(tunnelData).enter().append("g");
			var selectTunnelX = 0;
			var tabCnt = 1;

			tunnels.each(function(tunnel,index,tunnels){
				var tunnelWidth = totalWidth * (tunnel.flex / flexTotal);
				var g = d3.select(this).attr("id", tunnel.code).attr("class",function(data){ return data.select ? "tunnel selectTunnel" : "tunnel"});

				g.append('rect')
				.attr('class','outer-rect')
				.attr('x', tunnelX)
				.attr('y', tunnelY)
				.attr('width', tunnelWidth)
				.attr('height', tunnelHeight)
				.attr('stroke', 'none')
				// .attr('fill', 'none');
				.attr('fill', '#444444');

				g.append('rect')
				.attr('class','inner-background')
				.attr('x', tunnelX + tunnelPadding + tunnelStrokeWidth/2)
				.attr('y', tunnelY + tunnelPadding + tunnelStrokeWidth/2)
				.attr('rx', 3)
				.attr('ry', 3)
				.attr('width', tunnelWidth - (tunnelPadding*2) - tunnelStrokeWidth)
				.attr('height', tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth)
				.attr('stroke', 'none')
				// .attr('fill', '#2E364B');
				.attr('fill', '#2C2C2C');

				// g.append('rect')
				// .attr('class','hatch')
				// .attr('x', tunnelX + tunnelPadding + tunnelStrokeWidth/2 + innerPadding/2)
				// .attr('y', tunnelY + tunnelPadding + tunnelStrokeWidth/2 + innerPadding/2)
				// .attr('rx', 3)
				// .attr('ry', 3)
				// .attr('width', tunnelWidth - (tunnelPadding*2) - tunnelStrokeWidth - innerPadding)
				// .attr('height', tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth - innerPadding)
				// .attr('stroke', 'none')
				// .attr('fill', 'url(#hatch)');

				// if(tunnel.code == "DBS"){
				// 	selectTunnelX = 2.4;
				// }else if(tunnel.code == "KHR"){
				// 	selectTunnelX = 2.4;
				// }else if (tunnel.code == "GRR"){
				// 	selectTunnelX = 2.5;
				// }else if (tunnel.code == "YKR"){
				// 	selectTunnelX = 2.5;
				// }else if(tunnel.code == "SBT"){
				// 	selectTunnelX = 2.3;
				// }else if(tunnel.code == "SDR"){
				// 	selectTunnelX = 2.2;
				// }

				selectTunnelX = 2.35;
				var t = tunnel.location;

				//터널탭 터널명 생성
				g.append('text')
				.attr('class','tunnelName')
				.attr('id',tunnel.location)
				// .attr('x', tunnel.code == "KHR" ? tunnelX+tunnelWidth/2.4 : tunnelX+tunnelWidth/2.5)
				.attr('x', tunnelX+tunnelWidth/selectTunnelX)
				.attr('y', tunnelY+tunnelHeight/1.5)
				.attr('width', tunnelWidth)
				.attr('height', tunnelHeight)
				.attr("style", "text-align: center; font-size: 1.2rem; opacity:1; fill:white; transform:translate("+tunnelWidth/10+", 0px);")
				.text(tunnel.location);

				g.on("click", function(data) {
					me.selectTunnel(data);
					me.event.click(data); 
				});

				g.on("mouseover", function() { g.classed("hoverTunnel",true); });
				g.on('mouseout', function() { g.classed("hoverTunnel",false); });

				tunnelX += tunnelWidth;

				// 탭별로 중앙 위치 찾기
				var tabCenter = tunnelWidth*(tabCnt-0.5);

				// 탭별로 텍스트 크기, 중앙 위치 찾기
				var textElement = document.getElementById(tunnel.location);
				var bbox = textElement.getBBox();
				var tabTextWidthCenter = bbox.width/2;

				// 탭별로 텍스트 중앙 위치
				var tabNameCenter = tabCenter-tabTextWidthCenter;

				document.getElementById(tunnel.location).setAttribute('x', tabNameCenter);

				tabCnt++;
			})
		},
		redraw : function() {

			if(this.svg == null || !this.isShow) return;

			var me = this;
			var svg = me.svg;
			var mapContainer = d3.select("#"+me.mapcontainerId);
			var parentNode = mapContainer.node().parentNode;

			var width = (parentNode.clientWidth);
			var height = (parentNode.clientHeight) - (parseInt(getComputedStyle(parentNode).paddingTop) * 2);

			var tunnelPadding = me.tunnelPadding;
			var tunnelStrokeWidth = me.tunnelStrokeWidth;
			var tunnelHeight = height - (tunnelPadding + tunnelStrokeWidth);
			var totalWidth = width - (tunnelStrokeWidth*2);
			var tunnelX = tunnelStrokeWidth;
			var tunnelY = tunnelStrokeWidth;
			var innerPadding = me.innerPadding;

			var flexTotal = 0;
			var tunnelData = me.tunnelData;

			for(var i = 0; i < tunnelData.length; i++) {
				flexTotal += tunnelData[i].flex;
			}

			if(tunnelWidth <= 0 || tunnelHeight <= 0) {
				svg.attr("display","none");
				return;
			} else {
				svg.attr("display");
			}

			svg.attr("width", width)
			.attr("height", height)
			.attr("viewBox", [0,0,width,height]);

			svg.select('#tunnelMapBox')
			.attr('x', tunnelX)
			.attr('y', tunnelY)
			.attr('width', totalWidth)
			.attr('height', tunnelHeight);

			var divLineGroup = svg.select(".lineGroup");
			var dlX = tunnelX + (totalWidth * (tunnelData[0].flex / flexTotal));
			var lineFunc = d3.line()
			.x(function(d) { return d.x })
			.y(function(d) { return d.y });

			for(var i = 1; i < tunnelData.length; i++) {
				var tunnelWidth = totalWidth * (tunnelData[i].flex / flexTotal);
				var g = divLineGroup.select(".line"+i);
				// var dl = [{x: dlX, y: tunnelY-20},{x: dlX, y: tunnelY+tunnelHeight+20}]
				var dl = [{x: dlX, y: tunnelY},{x: dlX, y: tunnelY+tunnelHeight}]

				g.attr('d', lineFunc(dl));

				dlX += tunnelWidth;
			}
			
			var tunnelGroup = svg.select(".tunnelGroup");
			var tunnels = tunnelGroup.selectAll("g");

			tunnels.each(function(tunnel,index,tunnels){
				var tunnelWidth = totalWidth * (tunnel.flex / flexTotal);
				var g = d3.select(this);

				g.select('.outer-rect')
				.attr('x', tunnelX)
				.attr('y', tunnelY)
				.attr('width', tunnelWidth)
				.attr('height', tunnelHeight);

				var innerBack = g.select('.inner-background');
				if(tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth > 0) {
					innerBack.attr('x', tunnelX + tunnelPadding + tunnelStrokeWidth/2)
					.attr('y', tunnelY + tunnelPadding + tunnelStrokeWidth/2)
					.attr('width', tunnelWidth - (tunnelPadding*2) - tunnelStrokeWidth)
					.attr('height', tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth)
					.attr("display","");
				} else {
					innerBack.attr("display","none");
				}

				var hatch = g.select('.hatch');
				if(tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth - innerPadding > 0) {
					hatch.attr('x', tunnelX + tunnelPadding + tunnelStrokeWidth/2 + innerPadding/2)
					.attr('y', tunnelY + tunnelPadding + tunnelStrokeWidth/2 + innerPadding/2)
					.attr('width', tunnelWidth - (tunnelPadding*2) - tunnelStrokeWidth - innerPadding)
					.attr('height', tunnelHeight - (tunnelPadding*2) - tunnelStrokeWidth - innerPadding)
					.attr("display","");
				} else {
					hatch.attr("display","none");
				}

				//터널탭 터널명 생성
				var tunnelName = g.select('.tunnelName');
				tunnelName.attr('y', tunnelY+tunnelHeight/1.5)

				tunnelX += tunnelWidth;
			})
		},
		selectTunnel : function(data) {
			this.svg.selectAll(".selectTunnel").classed("selectTunnel",false); 
			this.svg.select(".tunnelGroup").select("#"+data.code).attr("class","selectTunnel");
		},
		event : {
			click : function(){

			}
		}
	}

	tunnelMap.init();
	window.addEventListener("resize", function(){ tunnelMap.redraw(); });

	return tunnelMap;

}
