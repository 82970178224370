/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as d3 from "d3";
import { util } from "./util";
import store from "@/store";
import EventBus from "@/event-bus";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

import { setUpCount, setDownCount } from "@/components/utils/dataConnet.js";
export const RoadMap = function (option) {
  var gsGlobalTarget = store.getters["settingConfig/getTarget"]("gs");
  var directionUp = gsGlobalTarget.direction.up;
  var directionDown = gsGlobalTarget.direction.down;
  var getLoadConfigJson =
    store.getters["settingConfig/getTarget"]("loadConfigJson");
  //상행 길이
  var upStartX = getLoadConfigJson.upStartX;
  var upEndX = getLoadConfigJson.upEndX;
  // 하행 길이
  var downStartX = getLoadConfigJson.downStartX;
  var downEndX = getLoadConfigJson.downEndX;
  // 길이 나누는 수
  var signpostCount = getLoadConfigJson.signpostCount;
  // 나누는 단위 0.1 => 100
  var signpostDiv = getLoadConfigJson.signpostDiv;

  var upScaleLinear = d3.scaleLinear().domain([upStartX, upEndX]); // 상행 차선
  var downScaleLinear = d3.scaleLinear().domain([downStartX, downEndX]); // 하행 차선

  var upPointList = new Array(upStartX + 1);
  var downPointList = new Array(downEndX + 1);
  var upVehicleList = 0;
  var downVehicleList = 0;
  var roadMap = {
    mapContainerId: "roadMap",
    isJaeyaksan: true,
    code: "",
    svg: null,
    svgId: null,
    svgClass: null,
    width: null,
    heigth: null,
    equipList: null,
    vehicleList: null,
    upBound: 1, // 상하행
    roadHeight: 0,
    roadPadding: 9,
    vehicleSize: 0, // 차량 크기
    vehicleSizePercent: 70,
    eventSize: 0,
    eventSizePercent: 70,
    laneBorderWidth: 1,

    // 속도 범례
    stagnation: 0, // 정체
    delay: 0, // 지체
    slow: 0, // 서행
    smooth: 0, // 원활

    // 도로 시작점, 종료점
    startPosition: getLoadConfigJson.startPosition,
    endPosition: getLoadConfigJson.endPosition,
    scheduleStartPosition: null,
    scheduleEndPosition: null,
    isShow: false,
    isRender: false,
    isEquipRender: false,
    needRedraw: false,
    eventList: {},
    detectList: {},
    viewCameraId: null,
    tooltip: {},
    scheduleTooltip: {},
    radarImgDir: {
      "IDS-01": 0,
      "IDS-02": 1,
      "IDS-03": 0,
      "IDS-04": 1,
    },
    iconConfig: {
      iconSize: { w: 20, h: 20 },
      radarImgUrl: {
        normal: {
          0: "resources/img/icons/dashboard/radar_map_up.svg",
          1: "resources/img/icons/dashboard/radar_map_down.svg",
        },
        detect: {
          0: "resources/img/icons/dashboard/radar_map_detect_up.svg",
          1: "resources/img/icons/dashboard/radar_map_detect_down.svg",
        },
      },
      cameraImgUrl: {
        normal: {
          0: "resources/img/icons/dashboard/cctv_map_up.svg",
          1: "resources/img/icons/dashboard/cctv_map_down.svg",
        },
        view: {
          0: "resources/img/icons/dashboard/cctv_map_up_active.svg",
          1: "resources/img/icons/dashboard/cctv_map_down_active.svg",
        },
        error: {
          0: "resources/img/icons/dashboard/cctv_map_up_error.svg",
          1: "resources/img/icons/dashboard/cctv_map_down_error.svg",
        },
      },
      soundImgUrl: {
        normal: {
          0: "resources/img/icons/dashboard/sound_event.svg",
          1: "resources/img/icons/dashboard/sound_event.svg",
        },
        detect: {
          0: "resources/img/icons/dashboard/sound_event.svg",
          1: "resources/img/icons/dashboard/sound_event.svg",
        },
        error: {
          0: "resources/img/icons/dashboard/sound_event_error.svg",
          1: "resources/img/icons/dashboard/sound_event_error.svg",
        },
      },
      yugoCameraImgUrl: {
        normal: {
          0: "resources/img/icons/dashboard/yugo_cctv_map_up.svg",
          1: "resources/img/icons/dashboard/yugo_cctv_map_down.svg",
        },
        view: {
          0: "resources/img/icons/dashboard/yugo_cctv_map_up_active.svg",
          1: "resources/img/icons/dashboard/yugo_cctv_map_down_active.svg",
        },
        error: {
          0: "resources/img/icons/dashboard/yugo_cctv_map_up_error.svg",
          1: "resources/img/icons/dashboard/yugo_cctv_map_down_error.svg",
        },
      },
      eventImgUrl: {
        "01": "resources/img/icons/dashboard/stopping-car_event.svg",
        "02": "resources/img/icons/dashboard/pedestrian_event.svg",
        "03": "resources/img/icons/dashboard/reverse_event.svg",
        "04": "resources/img/icons/dashboard/event/suddenStop.svg",
        "06": "resources/img/icons/dashboard/fire_event.svg",
        "09": "resources/img/icons/dashboard/event/bottleneck.svg",
        1: "resources/img/icons/dashboard/stopping-car_event.svg",
        2: "resources/img/icons/dashboard/pedestrian_event.svg",
        3: "resources/img/icons/dashboard/reverse_event.svg",
        4: "resources/img/icons/dashboard/event/suddenStop.svg",
        6: "resources/img/icons/dashboard/fire_event.svg",
        9: "resources/img/icons/dashboard/event/bottleneck.svg",
        11: "resources/img/icons/dashboard/event/sound.svg",
        12: "resources/img/icons/dashboard/event/sound.svg",
        13: "resources/img/icons/dashboard/event/sound.svg",
        14: "resources/img/icons/dashboard/event/sound.svg",
        //"99"
      },
    },
    vehicleLaneSize: {
      up: {},
      down: {},
    },
    selectLaneCnt: {
      up: {},
      down: {},
    },
    selectDirection: {
      up: {},
      down: {},
    },
    upVehicleSize: null,
    downVehicleSize: null,
    tunnelData: [],
    selectTunnelInfo: {},
    selectUpLaneY: null,
    selectDownLaneY: null,
    initTunnelInfo: {},
    selectTunnelPosition: null,
    selectTunnelCode: null,
    tunnelVehicleY: 0,
    roadLength: 0,
    eventEquipList: null,
    tunnelUpEndPosition: 0,
    tunnelDownEndPosition: 0,
    curveFunc: d3
      .line()
      .curve(d3.curveBasis)
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      }),
    init: function () {
      // 현재 터널 정보 수신
      EventBus.$on("selectTunnelInfo", (val) => {
        if (val == undefined) return;

        var val = val;
        if (val == undefined) {
          const keyArray = Object.keys(this.initTunnelInfo);
          // var numberOfArrays = keyArray.length / 2;
          // var selectTunnelIndex =
          //   numberOfArrays < 1 ? 0 : Math.floor(keyArray.length / 2);

          // val = this.initTunnelInfo[keyArray[selectTunnelIndex]];
          val = this.initTunnelInfo[keyArray[0]];
        }
        this.isShow = true;
        this.isRender = true;

        //터널코드
        if (val != undefined) {
          this.selectTunnelCode = val[0].code;
        }

        // //상행
        // var upBound = val[1].bound;
        // //하행
        // var downBound = val[0].bound;

        // //상행 차선
        // this.selectLaneCnt.up = val[1].lanecnt;
        // //하행 차선
        // this.selectLaneCnt.down = val[0].lanecnt;

        // //상행 방면 이름
        // this.selectDirection.up = val[1].direction;
        // //하행 방면 이름
        // this.selectDirection.down = val[0].direction;

        //금하지하차도 일 경우 A,B,C라인 표출
        if (this.selectTunnelCode == "KHR") {
          var g = this.svg.selectAll(".driveWayLineA");
          g.style("display", "");
        } else {
          var g = this.svg.selectAll(".driveWayLineA");
          g.style("display", "none");
        }

        //기존 점선 삭제
        //   if(val.length > 1){
        //   var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
        //   upLaneGroup.selectAll(".dash").remove();
        //   }
        //   var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
        //   downLaneGroup.selectAll(".dash").remove();

        //   var upScheduleGroup= this.svg.selectAll(".scheduleGroup" + upBound).remove();
        //   var downScheduleGroup = this.svg.selectAll(".scheduleGroup" + downBound).remove();

        //   //방면별 도로 y값 전달
        //   var pathCenterY = 0;
        //   var upLaneY = this.upLaneY - pathCenterY;
        //   var downLaneY = this.downLaneY - pathCenterY;

        //   //도로 reload 될 때 this.selectDrawLane 에 전달할 값
        //   this.selectTunnelInfo = val;
        //   this.selectUpLaneY = upLaneY;
        //   this.selectDownLaneY = downLaneY;

        //   // relay 테이블 단위 틀리게 해서 100 나눠줘야됨
        //   this.scheduleStartPosition = Number(val[0].startposition) / 100;
        //   this.scheduleEndPosition = Number(val[0].endposition) / 100 ;

        //   //선택된 터널 점선 생성
        //   this.selectDrawLane(val, 0, upLaneY, downLaneY);

        //   //선택된 터널 이정 생성
        //   this.selectdrawSignPost(val, 0, upLaneY, downLaneY);

        //   if(val.length > 1){
        //     //상행 차선별 개별차량 아이콘 사이즈
        //     switch (val[1].lanecnt) {
        //       case 3: {
        //         this.vehicleLaneSize.up = 5;
        //         break;
        //       }
        //       case 4: {
        //         this.vehicleLaneSize.up = 4.5;
        //         break;
        //       }
        //       case 5: {
        //         this.vehicleLaneSize.up = 4.1;
        //         break;
        //       }

        //       default:
        //         this.vehicleLaneSize.up = 0;
        //         break;
        //     }
        //   }

        //   //하행 차선별 개별차량 아이콘 사이즈
        //   switch (val[0].lanecnt) {
        //     case 3: {
        //       this.vehicleLaneSize.down = 5;
        //       break;
        //     }
        //     case 4: {
        //       this.vehicleLaneSize.down = 4.5;
        //       break;
        //     }
        //     case 5: {
        //       this.vehicleLaneSize.down = 4.1;
        //       break;
        //     }
        //     default:
        //       this.vehicleLaneSize.down = 0;
        //       break;
        //   }
        // });

        if (this.selectTunnelCode == "DBS") {
          //하행
          var downBound = val[0].bound;

          //하행 차선
          this.selectLaneCnt.down = val[0].lanecnt;

          //하행 방면 이름
          this.selectDirection.down = val[0].direction;

          //기존 점선 삭제
          var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
          downLaneGroup.selectAll(".dash").remove();

          //방면별 도로 y값 전달
          var pathCenterY = 0;
          var upLaneY = this.upLaneY - pathCenterY;
          var downLaneY = this.downLaneY - pathCenterY;

          //도로 reload 될 때 this.selectDrawLane 에 전달할 값
          this.selectTunnelInfo = val;
          this.selectUpLaneY = upLaneY;
          this.selectDownLaneY = downLaneY;

          // relay 테이블 단위 틀리게 해서 100 나눠줘야됨
          this.scheduleStartPosition = Number(val[0].startposition) / 100;
          this.scheduleEndPosition = Number(val[0].endposition) / 100;

          //선택된 터널 점선 생성
          this.dbsSelectDrawLane(val, 0, upLaneY);

          //선택된 터널 이정 생성
          this.dbsSelectdrawSignPost(val, 0, upLaneY);

          //하행 차선별 개별차량 아이콘 사이즈
          switch (val[0].lanecnt) {
            case 3: {
              this.vehicleLaneSize.down = 5;
              break;
            }
            case 4: {
              this.vehicleLaneSize.down = 4.5;
              break;
            }
            case 5: {
              this.vehicleLaneSize.down = 4.1;
              break;
            }
            default:
              this.vehicleLaneSize.down = 0;
              break;
          }
        } else {
          //상행
          var upBound = val[1].bound;
          //하행
          var downBound = val[0].bound;

          //상행 차선
          this.selectLaneCnt.up = val[1].lanecnt;
          //하행 차선
          this.selectLaneCnt.down = val[0].lanecnt;

          //상행 방면 이름
          this.selectDirection.up = val[1].direction;
          //하행 방면 이름
          this.selectDirection.down = val[0].direction;

          //기존 점선 삭제
          var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
          upLaneGroup.selectAll(".dash").remove();
          var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
          downLaneGroup.selectAll(".dash").remove();

          //방면별 도로 y값 전달
          var pathCenterY = 0;
          var upLaneY = this.upLaneY - pathCenterY;
          var downLaneY = this.downLaneY - pathCenterY;

          //도로 reload 될 때 this.selectDrawLane 에 전달할 값
          this.selectTunnelInfo = val;
          this.selectUpLaneY = upLaneY;
          this.selectDownLaneY = downLaneY;

          // relay 테이블 단위 틀리게 해서 100 나눠줘야됨
          this.scheduleStartPosition = Number(val[0].startposition) / 100;
          this.scheduleEndPosition = Number(val[0].endposition) / 100;

          //선택된 터널 점선 생성
          this.selectDrawLane(val, 0, upLaneY, downLaneY);

          //선택된 터널 이정 생성
          this.selectdrawSignPost(val, 0, upLaneY, downLaneY);

          //상행 차선별 개별차량 아이콘 사이즈
          switch (val[1].lanecnt) {
            case 3: {
              this.vehicleLaneSize.up = 5;
              break;
            }
            case 4: {
              this.vehicleLaneSize.up = 4.5;
              break;
            }
            case 5: {
              this.vehicleLaneSize.up = 4.1;
              break;
            }

            default:
              this.vehicleLaneSize.up = 0;
              break;
          }

          //하행 차선별 개별차량 아이콘 사이즈
          switch (val[0].lanecnt) {
            case 3: {
              this.vehicleLaneSize.down = 5;
              break;
            }
            case 4: {
              this.vehicleLaneSize.down = 4.5;
              break;
            }
            case 5: {
              this.vehicleLaneSize.down = 4.1;
              break;
            }
            default:
              this.vehicleLaneSize.down = 0;
              break;
          }
        }
      });

      // 공사이정 표출
      EventBus.$on("drawConstruction", (schedule) => {
        this.drawSchedule(schedule);
        // this.drawSchedule(g, 0, downLaneY, "down", 0, schedule);
      });

      var mapContainer = d3.select("#" + this.mapContainerId);
      this.mapContainer = mapContainer;

      var svg = mapContainer.append("svg");
      if (this.svgId) svg.attr("id", this.svgId);

      this.svg = svg;

      /* if(!this.isJaeyaksan) {
				this.roadPadding = 5;
			} */

      var defs = svg.append("defs");

      var normal = defs
        .append("radialGradient")
        .attr("id", "equipNormal" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      normal
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#2CE999; stop-opacity:1");

      normal
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#2CE999; stop-opacity:0.3");

      var error = defs
        .append("radialGradient")
        .attr("id", "equipError" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      error
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#E64359; stop-opacity:1");

      error
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#E64359; stop-opacity:0.3");

      var emphasis = defs
        .append("radialGradient")
        .attr("id", "equipEmphasis" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      emphasis
        .append("stop")
        .attr("offset", "55%")
        .attr("style", "stop-color:#E64359; stop-opacity:1");

      emphasis
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#E64359; stop-opacity:0.3");

      var vehicleGreen = defs
        .append("radialGradient")
        .attr("id", "vehicleGreen" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      vehicleGreen
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#2CE999; stop-opacity:1");

      vehicleGreen
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#2CE999; stop-opacity:0.3");

      var vehicleYellow = defs
        .append("radialGradient")
        .attr("id", "vehicleYellow" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      vehicleYellow
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#FFB431; stop-opacity:1");

      vehicleYellow
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#FFB431; stop-opacity:0.3");

      var vehicleOrange = defs
        .append("radialGradient")
        .attr("id", "vehicleOrange" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      vehicleOrange
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#FF8E43; stop-opacity:1");

      vehicleOrange
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#FF8E43; stop-opacity:0.3");

      var vehicleRed = defs
        .append("radialGradient")
        .attr("id", "vehicleRed" + this.code)
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%")
        .attr("fx", "50%")
        .attr("fy", "50%");

      vehicleRed
        .append("stop")
        .attr("offset", "15%")
        .attr("style", "stop-color:#E64359; stop-opacity:1");

      vehicleRed
        .append("stop")
        .attr("offset", "100%")
        .attr("style", "stop-color:#E64359; stop-opacity:0.3");

      var dropShadow = defs
        .append("filter")
        .attr("id", "dropshadow")
        .attr("height", "130%");

      dropShadow
        .append("feOffset")
        .attr("result", "offOut")
        .attr("in", "SourceGraphic")
        .attr("dx", "0")
        .attr("dy", "20");

      dropShadow
        .append("feGaussianBlur")
        .attr("result", "blurOut")
        .attr("in", "offOut")
        .attr("stdDeviation", "10");

      dropShadow
        .append("feBlend")
        .attr("in", "SourceGraphic")
        .attr("in2", "blurOut")
        .attr("mode", "normal");
    },
    calcSize: function () {
      var mapContainer = this.mapContainer;

      var parentNode = mapContainer.node().parentNode;

      var width = parentNode.clientWidth;
      var height =
        parentNode.clientHeight -
        parseInt(getComputedStyle(parentNode).paddingTop) * 2;

      // road
      var roadHeight = height / 2.7;
      var roadPadding = this.roadPadding;
      //var centerHeigth = 10;

      var laneHeight = roadHeight / 2.6 - roadPadding;

      var upLaneY =
        height / 2 -
        roadHeight / 2.6 +
        roadPadding * (2 / 3) +
        (laneHeight - laneHeight / 1.1);
      var downLaneY = height / 2 + roadPadding * (1 / 3) + laneHeight / 1.1;

      this.width = width;
      this.height = height;

      this.roadHeight = roadHeight;
      this.laneHeight = laneHeight;

      this.upLaneY = upLaneY;
      this.downLaneY = downLaneY;

      this.vehicleSize = laneHeight * (this.vehicleSizePercent / 100 / 4);
      this.eventSize = laneHeight * (this.eventSizePercent / 150);
    },
    draw: function () {
      this.isShow = true;
      this.isRender = true;
      this.drawRoad();
    },
    redraw: function () {
      this.needRedraw = true;
      if (!this.isShow) return;
      this.needRedraw = false;

      var me = this;

      this.calcSize();

      var width = this.width;
      var height = this.height;

      this.svg
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height]);

      // this.redrawRoad();
      // //선택된 터널 도로 다시 생성
      // this.selectDrawLane(
      //   this.selectTunnelInfo,
      //   0,
      //   this.upLaneY,
      //   this.downLaneY
      // );
      // //선택된 터널 이정 다시 생성
      // this.selectdrawSignPost(
      //   this.selectTunnelInfo,
      //   0,
      //   this.upLaneY,
      //   this.downLaneY
      // );

      if (this.selectTunnelCode == "DBS") {
        //선택된 터널 도로 다시 생성
        this.dbsSelectDrawLane(this.selectTunnelInfo, 0, this.upLaneY);
        //선택된 터널 이정 다시 생성
        this.dbsSelectdrawSignPost(this.selectTunnelInfo, 0, this.upLaneY);
      } else {
        //선택된 터널 도로 다시 생성
        this.selectDrawLane(
          this.selectTunnelInfo,
          0,
          this.upLaneY,
          this.downLaneY
        );
        //선택된 터널 이정 다시 생성
        this.selectdrawSignPost(
          this.selectTunnelInfo,
          0,
          this.upLaneY,
          this.downLaneY
        );
      }

      this.redrawEquip();

      setTimeout(function () {
        me.redrawVehicle(0);
      }, 0);

      setTimeout(function () {
        me.redrawVehicle(1);
      }, 0);

      me.redrawEvent();
    },
    drawRoad: function () {
      this.calcSize();

      var svg = this.svg;

      var width = this.width;
      var height = this.height;

      svg
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height]);

      var roadHeight = this.roadHeight;

      var g = svg.append("g").attr("class", "road");

      var pathCenterY = 0;
      var upLaneY = this.upLaneY - pathCenterY;
      var downLaneY = this.downLaneY - pathCenterY;

      var centerLane = this.getLaneData(
        0,
        height / 2 - pathCenterY,
        width,
        roadHeight
      );

      var centerPath = g
        .append("path")
        .attr("class", "centerPath")
        .attr("d", this.curveFunc(centerLane))
        .attr("fill", "none");

      this.centerPath = centerPath;

      this.drawSignPost(g, centerLane);

      this.upLanePath = this.drawLane(g, 0, upLaneY, "up", 1);
      this.downLanePath = this.drawLane(g, 0, downLaneY, "down", 0);

      // // scheduleTooltip 추가
      // var scheduleTooltip = svg
      //   .select(".road")
      //   .append("div")
      //   .attr("class", "scheduleTooltip")
      //   .style("width", "0px")
      //   .style("height", "20px")
      //   // .style("background-color","#181D2C")
      //   // 23.06.22. jy 툴팁 배경 색상 수정(도로 색상 기준)
      //   .style("background-color", "#2C2C2C")
      //   .style("color", "white")
      //   .style("text-align", "center")
      //   .style("border-radius", "3px")
      //   .style("font-size", "10pt")
      //   .style("position", "absolute")
      //   .style("z-index", "10")
      //   .style("visibility", "hidden");

      // this.scheduleTooltip = scheduleTooltip;

      this.drawBoundSign(g, 0, 0);
      this.drawBoundSign(g, 0, 1);

      //진입로 A,B,C라인 생성
      // this.drawDriveWayLine(g, 0, 0);

      var upLaneNode = this.upLanePath.select(".dash").node();
      var downLaneNode = this.downLanePath.select(".dash").node();

      upScaleLinear.range([20, this.width - 20]);
      downScaleLinear.range([20, this.width - 20]);

      for (var i = 0; i < upPointList.length; i++) {
        upPointList[i] = upLaneNode.getPointAtLength(upScaleLinear(i)).y;
      }

      for (var i = 0; i < downPointList.length; i++) {
        downPointList[i] = downLaneNode.getPointAtLength(downScaleLinear(i)).y;
      }
    },
    drawLane: function (g, startX, startY, LaneType, bound) {
      var width = this.width;
      var roadHeight = this.roadHeight;
      var laneHeight = this.laneHeight;
      var laneBorderWidth = this.laneBorderWidth;

      var lane = this.getLaneData(startX, startY, width, this.roadHeight);
      var dash = this.getLaneData(
        startX + 8,
        startY,
        width - 16,
        this.roadHeight
      );

      var laneGroup = g.append("g").attr("class", "laneGroup" + bound);

      if (LaneType == "up") {
        laneGroup
          .append("path")
          .attr("class", "laneBorder")
          .attr(
            "d",
            this.curveFunc(
              this.getLaneData(startX, startY - 5, width, this.roadHeight)
            )
          )
          .attr("stroke", "#727A8E")
          .attr("stroke-width", laneHeight + 11)
          .attr("fill", "none");

        laneGroup
          .append("path")
          .attr("class", "roadShoulder")
          .attr(
            "d",
            this.curveFunc(
              this.getLaneData(startX, startY - 5, width, this.roadHeight)
            )
          )
          .attr("stroke", "#323232")
          .attr("stroke-width", laneHeight + 10)
          .attr("fill", "none");
      } else {
        laneGroup
          .append("path")
          .attr("class", "laneBorder")
          .attr(
            "d",
            this.curveFunc(
              this.getLaneData(startX, startY + 5, width, this.roadHeight)
            )
          )
          .attr("stroke", "#727A8E")
          .attr("stroke-width", laneHeight + 11)
          .attr("fill", "none");

        laneGroup
          .append("path")
          .attr("class", "roadShoulder")
          .attr(
            "d",
            this.curveFunc(
              this.getLaneData(startX, startY + 5, width, this.roadHeight)
            )
          )
          .attr("stroke", "#323232")
          .attr("stroke-width", laneHeight + 10)
          .attr("fill", "none");
      }

      laneGroup
        .append("path")
        .attr("class", "laneShoulder")
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#727A8E")
        .attr("stroke-width", laneHeight + laneBorderWidth * 2)
        .attr("fill", "none");

      // 실선
      laneGroup
        .append("path")
        .attr("class", "lane")
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#323232")
        .attr("stroke-width", laneHeight)
        .attr("fill", "none");

      // 점선
      laneGroup
        .append("path")
        .attr("class", "dash")
        .attr("d", this.curveFunc(dash))
        .attr("stroke", "#727A8E")
        .attr("stroke-dasharray", "5,13")
        .attr("fill", "none");

      return laneGroup;
    },
    drawSignPost: function (roadGroup, centerLane) {
      var g = roadGroup.append("g").attr("class", "signPost");

      var signpostPath = g
        .append("path")
        .attr("class", "signPostPath")
        .attr("d", this.curveFunc(centerLane))
        .attr("stroke", "#9E9E9E")
        .attr("fill", "none");

      var startPosition = this.startPosition;
      var endPosition = this.endPosition;
      var signpostLinear = d3
        .scaleLinear()
        .domain([0, this.width])
        .range([startPosition, endPosition]);

      var scaleLinear = d3
        .scaleLinear()
        .domain([startPosition, endPosition])
        .range([0, this.width]);

      var drawStartPosition = signpostLinear(0 + 40);
      var drawEndPosition = signpostLinear(this.width - 40);

      drawStartPosition = parseFloat(drawStartPosition.toFixed(1));
      drawEndPosition = parseFloat(drawEndPosition.toFixed(1));

      //if(drawStartPosition == startPosition) drawStartPosition += 0.1
      //if(drawEndPosition == endPosition) drawEndPosition -= 0.05

      var sub = drawEndPosition - drawStartPosition;
      //var count = 9-((3-sub) >= 0? Math.round((3-sub)) : 0);
      //var div = sub/(count-1);

      for (var i = 0; i < signpostCount; i++) {
        var position = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var positionText = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var x = scaleLinear(position);
        var y = signpostPath.node().getPointAtLength(x).y;
        var positionGroup = g
          .append("g")
          .datum(position)
          .attr("class", "position");

        positionGroup
          .append("rect")
          .attr("x", x - 50 / 2)
          // .attr("x",(x - (50/2))*1.25)
          .attr("y", y - 20 / 2)
          .attr("width", 50)
          .attr("height", 20)
          // .attr("fill", "#232323");
          // 23.06.22. jy 이정 배경 색상 수정(도로 색상 기준)
          .attr("fill", "#2C2C2C");

        positionGroup
          .append("svg:text")
          //.text(positionText+"k")
          .text(positionText * 1000 + "m")
          .attr("x", x)
          // .attr('x' , x*1.2)
          .attr("y", y)
          .attr("width", 50)
          .attr("height", 20)
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle")
          .attr("fill", "#848484")
          .attr("font-size", "1rem")
          .attr("font-family", "'NotoSansKR',Open Sans,sans-serif");
      }
    },
    drawBoundSign: function (roadGroup, startX, bound) {
      var g = roadGroup.append("g").attr("class", "boundSign" + bound);

      var leftGroup = g.append("g").attr("class", "leftSign");
      var rightGroup = g.append("g").attr("class", "rightSign");

      var roadHeight = this.roadHeight + 30;
      var roadPadding = this.roadPadding;
      var centerPath = this.centerPath;

      var signY = roadHeight + roadPadding;

      var y =
        centerPath.node().getPointAtLength(startX).y +
        (bound == 1 ? -signY : signY);
      // var y =
      //   centerPath.node().getPointAtLength(0).y +
      //   (bound == 1 ? -signY : signY);
      var boxHeight = 30;

      // if (bound == 0) {
      //   leftGroup
      //     .append("rect")
      //     .attr("x", startX)
      //     .attr("y", y - boxHeight / 2)
      //     .attr("rx", 5)
      //     .attr("ry", 5)
      //     .attr("width", 140)
      //     .attr("height", boxHeight)
      //     .attr("fill", "#2c2c2c");

      //   leftGroup
      //     .append("svg:text")
      //     // .text("한남동 방면 → "+downVehicleList+"대")
      //     // .text("하행 방면 → "+downVehicleList+"대")
      //     .html(
      //       directionDown +
      //         " 방면 → <tspan fill='#4e9dfe'>" +
      //         downVehicleList +
      //         "</tspan> 대"
      //     )
      //     .attr("x", 90)
      //     .attr("y", y + 2)
      //     .attr("width", 140)
      //     .attr("height", boxHeight)
      //     .attr("dominant-baseline", "middle")
      //     .attr("text-anchor", "middle")
      //     .attr("fill", "#E1E1E1")
      //     .attr("font-size", "1.2rem")
      //     .attr("font-family", "'NotoSansKR',Open Sans,sans-serif");
      // } else {
      //   // rightGroup
      //   //   .append("rect")
      //   //   .attr("x", this.width - 140)
      //   //   .attr("y", y - boxHeight / 2)
      //   //   .attr("rx", 5)
      //   //   .attr("ry", 5)
      //   //   .attr("width", 140)
      //   //   .attr("height", boxHeight)
      //   //   .attr("fill", "#2c2c2c");

      //   rightGroup
      //     .append("svg:text")
      //     // .text("필동 방면 ← "+upVehicleList+"대")
      //     // .text("상행 방면 ← "+upVehicleList+"대")
      //     .html(
      //       directionUp +
      //         " 방면 ← <tspan fill='#4e9dfe'>" +
      //         upVehicleList +
      //         "</tspan> 대"
      //     )
      //     .attr("id", "displayRightSign")
      //     .attr("x", this.width - 190 / 2)
      //     .attr("y", y + 12)
      //     .attr("width", 140)
      //     .attr("height", boxHeight)
      //     .attr("dominant-baseline", "middle")
      //     .attr("text-anchor", "middle")
      //     .attr("fill", "#E1E1E1")
      //     .attr("font-size", "1.2rem")
      //     .attr("font-family", "'NotoSansKR',Open Sans,sans-serif");
      // }
    },
    drawEquip: function () {
      var me = this;
      var svg = this.svg;

      var equipGroupG = svg.select(".equipGroup");

      if (!equipGroupG.empty()) {
        equipGroupG.remove();
      }

      var g = svg.append("g").attr("class", "equipGroup");
      // g.lower();

      var roadHeight = this.roadHeight;
      var roadPadding = this.roadPadding;
      var centerPath = this.centerPath;

      var equipStartY = roadHeight / 2 + roadPadding * 4;

      // var tunnelUpEndPosition = null;
      // var tunnelDownEndPosition = null;
      var selectTunnelCode = this.selectTunnelCode;

      //터널별 장비,차량,도로 y 설정
      switch (this.selectTunnelCode) {
        case "DBS": //도봉지하차도
          this.tunnelDownEndPosition = 1.25;
          this.tunnelVehicleY = 0.58;
          this.roadLength = 0;
          break;
        case "SBT": //수락방음터널
          this.tunnelUpEndPosition = 0.14;
          this.tunnelDownEndPosition = 0.12;
          this.tunnelVehicleY = 1.1;
          // this.roadLength = 5000;
          this.roadLength = 0;
          this.upEndY = 1500;
          break;
        case "SDR": //상도지하차도
          this.tunnelUpEndPosition = 1.145;
          this.tunnelDownEndPosition = 1.125;
          this.tunnelVehicleY = 3.2;
          // this.roadLength = 6605;
          this.roadLength = 0;
          break;
        case "GRR": //구룡지하차도
          this.tunnelUpEndPosition = 0.82;
          this.tunnelDownEndPosition = 0.8;
          this.tunnelVehicleY = 1.95;
          this.roadLength = 0;
          break;
        case "YKR": //염곡동서지하차도
          this.tunnelUpEndPosition = 1.1;
          this.tunnelDownEndPosition = 1.08;
          this.tunnelVehicleY = 3.03;
          this.roadLength = 0;
          break;
        case "KHR": //금하지하차도
          this.tunnelUpEndPosition = 0.58;
          this.tunnelDownEndPosition = 0.56;
          this.tunnelVehicleY = 1.56;
          this.roadLength = 0;
          break;
      }

      // var scaleLinear = d3
      //   .scaleLinear()
      //   .domain([this.startPosition, this.endPosition - tunnelDownEndPosition])
      //   .range([0, this.width]);

      var scaleLinear = null;
      if (selectTunnelCode == "DBS") {
        scaleLinear = d3
          .scaleLinear()
          .domain([
            this.startPosition,
            this.endPosition + this.tunnelDownEndPosition,
          ])
          .range([0, this.width]);
      } else {
        scaleLinear = d3
          .scaleLinear()
          .domain([
            this.startPosition,
            this.endPosition - this.tunnelDownEndPosition,
          ])
          .range([0, this.width]);
      }

      var equipIconUrl = this.iconConfig.radarImgUrl.normal;
      var equipIconUrlDetect = this.iconConfig.radarImgUrl.detect;
      var cameraIconUrl = this.iconConfig.cameraImgUrl.normal;
      var cameraIconUrlError = this.iconConfig.cameraImgUrl.error;
      var soundIconUrl = this.iconConfig.soundImgUrl.normal;
      var soundIconUrlError = this.iconConfig.soundImgUrl.error;
      var yugoCameraIconUrl = this.iconConfig.yugoCameraImgUrl.normal;
      var yugoCameraIconUrlError = this.iconConfig.yugoCameraImgUrl.error;
      var iconSize = this.iconConfig.iconSize;
      var tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltipBody")
        .style("width", "0px")
        .style("height", "20px")
        // .style("background-color","#181D2C")
        // 23.06.22. jy 툴팁 배경 색상 수정(도로 색상 기준)
        .style("background-color", "#2C2C2C")
        .style("color", "white")
        .style("text-align", "center")
        .style("border-radius", "3px")
        .style("font-size", "10pt")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("visibility", "hidden");

      var equips = g
        .selectAll(null)
        .data(this.equipList)
        .enter()
        .append("g")
        .on("click", function (data, index, paths) {
          if (data.tech == "C") me.event.equipClick(data, index);
        })
        .on("mouseover", function (data, index, paths) {
          me.event.equipMouseover(data, index, tooltip);
        })
        .on("mouseout", function (data, index, paths) {
          me.event.equipMouseout(data, index, tooltip);
        });

      var code = this.code;
      var upBound = this.upBound;
      equips.each(function (data, index, list) {
        var way = data.direction == "1" ? 1 : 0;
        var eg = d3
          .select(this)
          .attr("id", "camera" + data.cameraId.trim())
          .attr("class", "equip");
        var iconLength = iconSize.w;
        var bound = data.bound == upBound ? 1 : 0;
        var x =
          data.bound == upBound
            ? // ? this.selectTunnelCode == "DBS" ? scaleLinear(data.position) : scaleLinear(me.endPosition - data.position - tunnelUpEndPosition)
              // ? scaleLinear(me.endPosition - data.position - tunnelUpEndPosition)
              scaleLinear(data.position)
            : scaleLinear(data.position);
        var y =
          centerPath.node().getPointAtLength(x).y +
          (data.bound == upBound ? -equipStartY : equipStartY);
        var state = data.state == 0 ? "equipNormal" : "equipError";
        // var state =
        //   data.state == 0
        //     ? "equipNormal"
        //     : data.state == 2
        //     ? "equipNormal"
        //     : "equipError";

        x = x - iconLength / 2;

        var textY = 15;

        if (bound == 0) textY *= -1;

        var instHeight = data.instHeight;
        var iY = 0;
        var cY = 0;
        var instY = 0;
        if (bound == 0) {
          cY = y + 30 * instHeight * bound;
          // iY = y + 20 * instHeight - iconSize.h / 2;
          // instY = y + 20 * instHeight;
          iY =
            selectTunnelCode == "KHR"
              ? y + 30 * instHeight + iconSize.h * 2.7
              : y + 30 * instHeight - iconSize.h / 2;
          instY =
            selectTunnelCode == "KHR"
              ? (y + 30 * instHeight) * 1.23
              : y + 30 * instHeight;
        } else {
          cY = y - 30 * instHeight;
          iY = y - 30 * instHeight - iconSize.h / 2;
          instY = y - 30 * instHeight;
        }

        // if (data.tech.indexOf("S") != -1) {
        //   var updownImagBound = data.bound == 1 ? 1 : 0;
        //   eg.append("circle")
        //     .attr("class", "detect")
        //     .style("display", "none")
        //     .attr("cx", x + iconSize.w)
        //     .attr("cy", instY)
        //     .attr("r", iconSize.w * 1.3)
        //     .attr("stroke", "#FC525E63")
        //     .attr("fill", "#FC525E17");

        //   eg.append("svg:image")
        //     .attr("class", "equipImg")
        //     .attr("x", x + iconSize.w / 2)
        //     //.attr('x' , (x + (iconSize.w/2))*1.25)
        //     //.attr('y' , y - (iconSize.h/2))
        //     .attr("y", iY)
        //     .attr("width", iconSize.w)
        //     .attr("height", iconSize.h)
        //     .attr("opacity", 1)
        //     .attr(
        //       "xlink:href",
        //       state == "equipNormal"
        //         ? soundIconUrl[updownImagBound]
        //         : soundIconUrlError[updownImagBound]
        //     );
        // } else if (data.tech.indexOf("R") != -1) {
        //   var cameraIndex = data.cameraIndex;
        //   var radarBound = data.bound == upBound ? -1 : 1;
        //   var updownImagBound = data.bound == 1 ? 1 : 0;
        //   var radarHeight = data.instHeight;
        //   eg.append("circle")
        //     .attr("class", "detect")
        //     .style("display", "none")
        //     .attr("cx", x + iconSize.w / 2)
        //     .attr("cy", instY)
        //     .attr("r", iconSize.w * 1.3)
        //     .attr("stroke", "#FC525E63")
        //     .attr("fill", "#FC525E17");
        //   eg.append("svg:image")
        //     .attr("class", "equipImg")
        //     .attr("x", x)
        //     //.attr('x' , x*1.25)
        //     //.attr('y' , y+(20*radarHeight*radarBound) - (iconSize.h/2))
        //     .attr("y", iY)
        //     .attr("width", iconSize.w)
        //     .attr("height", iconSize.h)
        //     .attr("opacity", 1)
        //     //  .attr("xlink:href", equipIconUrl[cameraIndex-1]);z
        //     .attr(
        //       "xlink:href",
        //       state == "equipNormal"
        //         ? equipIconUrl[way]
        //         : equipIconUrlDetect[way]
        //       // equipIconUrl[way]
        //     );
        // } else {
        //   var updownImagBound = data.bound == 1 ? 1 : 0;

        //   eg.append("circle")
        //     .attr("class", "detect")
        //     .style("display", "none")
        //     .attr("cx", x + iconSize.w / (updownImagBound == 1 ? 1.5 : 2))
        //     .attr("cy", instY)
        //     .attr("r", iconSize.w * 1.3)
        //     .attr("stroke", "#FC525E63")
        //     .attr("fill", "#FC525E17");

        //   eg.append("svg:image")
        //     .attr("class", "equipImg")
        //     .style("cursor", "pointer")
        //     .attr(
        //       "width",
        //       data.yugoDetectorYn == "N" ? iconSize.w : iconSize.w * 1.25
        //       // iconSize.w
        //     )
        //     .attr("height", iconSize.h)
        //     .attr("x", x)
        //     // .attr('x' , x + (iconSize.w/2))
        //     //.attr('y' , y - (iconSize.h/2))
        //     .attr("y", iY)
        //     .attr("opacity", 1)
        //     .attr(
        //       "xlink:href",
        //       data.yugoDetectorYn == "N"
        //       ? cameraIconUrl[way]
        //       : state == "equipNormal"
        //       ? yugoCameraIconUrl[way]
        //       : yugoCameraIconUrlError[way]
        //       // cameraIconUrl[way]
        //     );
        //   // .attr("xlink:href", cameraIconUrl[bound]);
        // }

        if (selectTunnelCode != "DBS") {
          if (data.tech.indexOf("S") != -1) {
            var updownImagBound = data.bound == 1 ? 1 : 0;
            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr("cx", x + iconSize.w)
              .attr("cy", instY)
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");

            eg.append("svg:image")
              .attr("class", "equipImg")
              .attr("x", x + iconSize.w / 2)
              //.attr('x' , (x + (iconSize.w/2))*1.25)
              //.attr('y' , y - (iconSize.h/2))
              .attr("y", iY)
              .attr("width", iconSize.w)
              .attr("height", iconSize.h)
              .attr("opacity", 1)
              .attr(
                "xlink:href",
                state == "equipNormal"
                  ? soundIconUrl[updownImagBound]
                  : soundIconUrlError[updownImagBound]
              );
          } else if (data.tech.indexOf("R") != -1) {
            var cameraIndex = data.cameraIndex;
            var radarBound = data.bound == upBound ? -1 : 1;
            var updownImagBound = data.bound == 1 ? 1 : 0;
            var radarHeight = data.instHeight;
            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", instY)
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");
            eg.append("svg:image")
              .attr("class", "equipImg")
              .attr("x", x)
              //.attr('x' , x*1.25)
              //.attr('y' , y+(20*radarHeight*radarBound) - (iconSize.h/2))
              .attr("y", iY)
              .attr("width", iconSize.w)
              .attr("height", iconSize.h)
              .attr("opacity", 1)
              //  .attr("xlink:href", equipIconUrl[cameraIndex-1]);z
              .attr(
                "xlink:href",
                state == "equipNormal"
                  ? equipIconUrl[way]
                  : equipIconUrlDetect[way]
                // equipIconUrl[way]
              );
          } else {
            var updownImagBound = data.bound == 1 ? 1 : 0;

            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr("cx", x + iconSize.w / (updownImagBound == 1 ? 1.5 : 2))
              .attr("cy", instY)
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");

            eg.append("svg:image")
              .attr("class", "equipImg")
              .style("cursor", "pointer")
              .attr(
                "width",
                data.yugoDetectorYn == "N" ? iconSize.w : iconSize.w * 1.25
                // iconSize.w
              )
              .attr("height", iconSize.h)
              .attr("x", x)
              // .attr('x' , x + (iconSize.w/2))
              //.attr('y' , y - (iconSize.h/2))
              .attr("y", iY)
              .attr("opacity", 1)
              .attr(
                "xlink:href",
                data.yugoDetectorYn == "N"
                  ? cameraIconUrl[way]
                  : state == "equipNormal"
                  ? yugoCameraIconUrl[way]
                  : yugoCameraIconUrlError[way]
                // cameraIconUrl[way]
              );
            // .attr("xlink:href", cameraIconUrl[bound]);
          }
        } else {
          //도봉지하차도 장비
          var dbsWay = data.direction == "1" ? 0 : 1;

          var slipRoadEquip = false;
          const sCircleDetectY =
            bound == 0 ? (y + instHeight) / 3 : (y + instHeight) * 1.38;
          const rCircleDetectY =
            bound == 0 ? (y + instHeight) / 4 : y - instHeight / 2;
          const cCircleDetectY = bound == 0 ? (y + instHeight) * 0.18 : y * 1.5;

          if (bound == 0) {
            //도로
            cY = y - 20 * instHeight;
            iY = y - 20 * instHeight - iconSize.h / 2;
            instY = y - 20 * instHeight;
          } else {
            //진입로
            cY = y + 20 * instHeight * bound;
            iY = y + 20 * instHeight - iconSize.h / 2;
            instY = y + 20 * instHeight;
          }

          if (data.tech.indexOf("S") != -1) {
            var updownImagBound = data.bound == 1 ? 0 : 1;

            //음원#24
            if (data.cameraId == "DBS-SND-E-00223") {
              slipRoadEquip = true;
              x = x + iconSize.w * 1.7;
              //음원#26
            } else if (data.cameraId == "DBS-SND-E-00243") {
              slipRoadEquip = true;
              x = x + iconSize.w * 0.2;
            }

            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr(
                "cx",
                slipRoadEquip == false
                  ? x + iconSize.w * 1.3 + iconSize.w / 2
                  : x / 0.987
              )
              .attr(
                "cy",
                slipRoadEquip == false
                  ? sCircleDetectY
                  : (y + instHeight) * 1.385
              )
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");

            eg.append("svg:image")
              .attr("class", "equipImg")
              .attr("x", slipRoadEquip == false ? x + iconSize.w * 1.3 : x)
              .attr("y", slipRoadEquip == false ? iY * 0.3 : iY * 3.6) // 앞이 상행 뒤에 하행
              .attr("width", iconSize.w)
              .attr("height", iconSize.h)
              .attr("opacity", 1)
              .attr(
                "xlink:href",
                state == "equipNormal"
                  ? soundIconUrl[updownImagBound]
                  : soundIconUrlError[updownImagBound]
              );
          } else if (data.tech.indexOf("R") != -1) {
            var cameraIndex = data.cameraIndex;
            var radarBound = data.bound == upBound ? -1 : 1;
            var updownImagBound = data.bound == 1 ? 0 : 1;
            var radarHeight = data.instHeight;
            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", rCircleDetectY)
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");
            eg.append("svg:image")
              .attr("class", "equipImg")
              .attr("x", x)
              .attr("y", bound == 1 ? iY * 1.01 : iY * 0.23) // 앞이 상행 뒤에 하행
              .attr("width", iconSize.w)
              .attr("height", iconSize.h)
              .attr("opacity", 1)
              .attr(
                "xlink:href",
                state == "equipNormal"
                  ? equipIconUrl[dbsWay]
                  : equipIconUrlDetect[dbsWay]
              );
          } else {
            if (instHeight == 1) {
              iY = iY * 1.4;
            } else if (instHeight == 3) {
              iY = iY * 1.9;
            }
            var updownImagBound = data.bound == 1 ? 0 : 1;

            if (data.cameraId == "DBS-CAM-A-10000") {
              //CAM#31
              slipRoadEquip = true;
              x = x + iconSize.w * 31.5;
            } else if (data.cameraId == "DBS-CAM-A-10100") {
              //CAM#32
              slipRoadEquip = true;
              x = x + iconSize.w * 30.5;
            } else if (data.cameraId == "DBS-CAM-A-11250") {
              //CAM#33
              slipRoadEquip = true;
              x = x - iconSize.w;
            }

            eg.append("circle")
              .attr("class", "detect")
              .style("display", "none")
              .attr(
                "cx",
                slipRoadEquip == false
                  ? x + iconSize.w / 2
                  : x + iconSize.w * 0.4
              )
              .attr(
                "cy",
                slipRoadEquip == false ? cCircleDetectY : cCircleDetectY * 6.9
              )
              .attr("r", iconSize.w * 1.3)
              .attr("stroke", "#FC525E63")
              .attr("fill", "#FC525E17");

            eg.append("svg:image")
              .attr("class", "equipImg")
              .style("cursor", "pointer")
              .attr(
                "width",
                data.yugoDetectorYn == "N" ? iconSize.w : iconSize.w * 1.25
              )
              .attr("height", iconSize.h)
              .attr("x", x)
              .attr("y", slipRoadEquip == false ? iY * 0.17 : iY / 0.38) // 앞이 상행 뒤에 하행
              .attr("opacity", 1)
              .attr(
                "xlink:href",
                data.yugoDetectorYn == "N"
                  ? cameraIconUrl[dbsWay]
                  : state == "equipNormal"
                  ? yugoCameraIconUrl[dbsWay]
                  : yugoCameraIconUrlError[dbsWay]
              )
              .attr("text", data.yugoDetectorYn);
          }
        }
      });

      this.isEquipRender = true;
    },
    redrawRoad: function () {
      var width = this.width;
      var height = this.height;
      var roadHeight = this.roadHeight;
      var laneHeight = this.laneHeight;
      var laneBorderWidth = this.laneBorderWidth;

      var pathCenterY = 0;
      var upLaneY = this.upLaneY - pathCenterY;
      var downLaneY = this.downLaneY - pathCenterY;

      var centerLane = this.getLaneData(
        0,
        height / 2 - pathCenterY,
        width,
        roadHeight
      );
      var upLane = this.getLaneData(0, upLaneY, width, roadHeight);
      var upDash = this.getLaneData(8, upLaneY, width - 16, roadHeight);
      var downLane = this.getLaneData(0, downLaneY, width, roadHeight);
      var downDash = this.getLaneData(8, downLaneY, width - 16, roadHeight);

      var upLanePath = this.upLanePath;
      var downLanePath = this.downLanePath;

      this.centerPath
        .attr("d", this.curveFunc(centerLane))
        .attr("stroke-width", roadHeight);

      upLanePath
        .select(".laneBorder")
        .attr(
          "d",
          this.curveFunc(this.getLaneData(0, upLaneY - 5, width, roadHeight))
        )
        .attr("stroke-width", laneHeight + 11);

      upLanePath
        .select(".roadShoulder")
        .attr(
          "d",
          this.curveFunc(this.getLaneData(0, upLaneY - 5, width, roadHeight))
        )
        .attr("stroke-width", laneHeight + 10);

      upLanePath
        .select(".laneShoulder")
        .attr("d", this.curveFunc(upLane))
        .attr("stroke-width", laneHeight + laneBorderWidth * 2);

      upLanePath
        .select(".lane")
        .attr("d", this.curveFunc(upLane))
        .attr("stroke-width", laneHeight);

      upLanePath.select(".dash").attr("d", this.curveFunc(upDash));

      downLanePath
        .select(".laneBorder")
        .attr(
          "d",
          this.curveFunc(this.getLaneData(0, downLaneY + 5, width, roadHeight))
        )
        .attr("stroke-width", laneHeight + 11);

      downLanePath
        .select(".roadShoulder")
        .attr(
          "d",
          this.curveFunc(this.getLaneData(0, downLaneY + 5, width, roadHeight))
        )
        .attr("stroke-width", laneHeight + 10);

      downLanePath
        .select(".laneShoulder")
        .attr("d", this.curveFunc(downLane))
        .attr("stroke-width", laneHeight + laneBorderWidth * 2);

      downLanePath
        .select(".lane")
        .attr("d", this.curveFunc(downLane))
        .attr("stroke", "#727A8E")
        .attr("stroke-dasharray", "5,13")
        .attr("stroke-width", laneHeight);

      downLanePath.select(".dash").attr("d", this.curveFunc(downDash));

      this.redrawSignPost(centerLane);
      this.redrawBoundSign(0, 0);
      this.redrawBoundSign(1, 0);

      var upLaneNode = upLanePath.select(".dash").node();
      var downLaneNode = downLanePath.select(".dash").node();

      upScaleLinear.range([20, this.width - 20]);
      downScaleLinear.range([20, this.width - 20]);

      for (var i = 0; i < upPointList.length; i++) {
        upPointList[i] = upLaneNode.getPointAtLength(upScaleLinear(i)).y;
      }

      for (var i = 0; i < downPointList.length; i++) {
        downPointList[i] = downLaneNode.getPointAtLength(downScaleLinear(i)).y;
      }
    },
    redrawSignPost: function (centerLane) {
      var g = this.svg.select(".road").select(".signPost");

      var signpostPath = g
        .select(".signPostPath")
        .attr("d", this.curveFunc(centerLane));

      var scaleLinear = d3
        .scaleLinear()
        .domain([this.startPosition, this.endPosition])
        .range([0, this.width]);

      var positionGroups = g.selectAll(".position");
      var node = signpostPath.node();

      positionGroups.each(function (data, index, list) {
        var positionGroup = d3.select(this);

        var x = scaleLinear(data);
        var y = node.getPointAtLength(x).y;

        positionGroup
          .select("rect")
          .attr("x", x - 50 / 2)
          .attr("y", y - 20 / 2);

        positionGroup.select("text").attr("x", x).attr("y", y);
      });
    },
    redrawBoundSign: function (bound, data) {
      //터널별 방면 이름
      var selectDirectionUp = this.selectDirection.up;
      var selectDirectionDown = this.selectDirection.down;

      // "상,하행 ->" 표시
      var svg = this.svg;
      var g = svg.select(".road").select(".boundSign" + bound);

      var leftGroup = g.select(".leftSign");
      var rightGroup = g.select(".rightSign");

      var roadHeight = this.roadHeight + 30;
      var roadPadding = this.roadPadding;
      var centerPath = this.centerPath;

      var signY = roadHeight + roadPadding;

      // 대시보드 방면 표시 위치 왼쪽, 오른쪽 변경
      var y =
        centerPath.node().getPointAtLength(0).y + (bound == 1 ? -signY : signY);

      const displayRightSign = document.getElementById("displayRightSign"); // 도봉지하차도 아니면 상행 방면 보이게 설정

      if (this.selectTunnelCode != "DBS") {
        // 원본코드 : if (bound !== 0) {
        if (bound == 0) {
          leftGroup
            .select("rect")
            .attr("x", this.width - 190 / 2)
            .attr("y", y - 26 / 2);
          leftGroup
            .select("text")
            // .text("하행 방면 → "+data+"대")
            // .html("하행 방면 → <tspan fill='#4e9dfe'>" + data + "</tspan> 대")
            .html(
              // directionDown +
              selectDirectionDown +
                " 방면 → <tspan fill='#4e9dfe'>" +
                downVehicleList +
                "</tspan> 대"
            )
            .attr(
              "x",
              this.selectTunnelCode == "GRR" ? this.width / 20 : this.width / 20
            )
            .attr("y", y);
        } else {
          rightGroup
            .select("rect")
            .attr("x", 90)
            .attr("y", y - 26 / 2);
          rightGroup
            .select("text")
            // .text("상행 방면 ← "+data+"대")
            // .html("상행123213 방면 → <tspan fill='#4e9dfe'>" + data + "</tspan> 대")
            .html(
              // directionUp +
              selectDirectionUp +
                " 방면 ← <tspan fill='#4e9dfe'>" +
                upVehicleList +
                "</tspan> 대"
            )
            .attr(
              "x",
              this.selectTunnelCode == "GRR"
                ? this.width - 150 * 0.7
                : this.width - 150 * 0.7
            )
            .attr("y", y + 12);
        }
        if (displayRightSign != null) {
          displayRightSign.style.display = "";
        }
      } else {
        leftGroup
          .select("rect")
          .attr("x", this.width - 190 / 2)
          .attr("y", y - 26 / 2);
        leftGroup
          .select("text")
          .html(
            selectDirectionDown +
              " 방면 → <tspan fill='#4e9dfe'>" +
              downVehicleList +
              "</tspan> 대"
          )
          .attr("x", this.width / 25)
          .attr("y", y);
        if (displayRightSign != null) {
          displayRightSign.style.display = "none";
        }
      }
    },
    settingVehicleColor: function (stagnation, delay, slow, smooth) {
      this.stagnation = Number(stagnation);
      this.delay = Number(delay);
      this.slow = Number(slow);
      this.smooth = Number(smooth);
    },
    redrawEquip: function () {
      var me = this;
      var g = this.svg.select(".equipGroup");

      // g.lower();

      var roadHeight = this.roadHeight;
      var roadPadding = this.roadPadding;
      var centerPath = this.centerPath;

      var equipStartY = roadHeight / 2 + roadPadding * 4;

      var selectTunnelCode = this.selectTunnelCode;

      //터널별 장비 y 설정
      switch (this.selectTunnelCode) {
        case "DBS": //도봉지하차도
          this.tunnelDownEndPosition = 1.25;
          break;
        case "SBT": //수락방음터널
          this.tunnelUpEndPosition = 0.14;
          this.tunnelDownEndPosition = 0.12;
          break;
        case "SDR": //상도지하차도
          this.tunnelUpEndPosition = 1.445;
          this.tunnelDownEndPosition = 1.425;
          break;
        case "GRR": //구룡지하차도
          this.tunnelUpEndPosition = 0.82;
          this.tunnelDownEndPosition = 0.8;
          break;
        case "YKR": //염곡동서지하차도
          this.tunnelUpEndPosition = 1.1;
          this.tunnelDownEndPosition = 1.08;
          break;
        case "KHR": //금하지하차도
          this.tunnelUpEndPosition = 0.58;
          this.tunnelDownEndPosition = 0.56;
          break;
      }

      // var scaleLinear = d3
      //   .scaleLinear()
      //   .domain([this.startPosition, this.endPosition])
      //   .range([0, this.width]);

      var scaleLinear = null;
      if (this.selectTunnelCode == "DBS") {
        scaleLinear = d3
          .scaleLinear()
          .domain([
            this.startPosition,
            this.endPosition + this.tunnelDownEndPosition,
          ])
          .range([0, this.width]);
      } else {
        scaleLinear = d3
          .scaleLinear()
          .domain([
            this.startPosition,
            this.endPosition - this.tunnelDownEndPosition,
          ])
          .range([0, this.width]);
      }

      var iconSize = this.iconConfig.iconSize;

      var equips = g.selectAll(".equip");
      var upBound = this.upBound;

      this.setZIndex(equips, "back");

      equips.each(function (data, index, list) {
        var eg = d3.select(this);

        var iconLength = iconSize.w;
        var bound = data.bound == upBound ? 1 : 0;
        var x =
          data.bound == upBound
            ? scaleLinear(me.endPosition - data.position)
            : scaleLinear(data.position);
        var y =
          centerPath.node().getPointAtLength(x).y +
          (data.bound == upBound ? -equipStartY : equipStartY);

        x = x - iconLength / 2;

        var textY = 15;
        if (bound == 0) textY *= -1;
        var rinstHeight = data.instHeight;
        var riY = 0;
        var rcY = 0;
        var rinstY = 0;
        if (bound == 0) {
          rcY = y + 30 * rinstHeight * bound;
          // riY = y + 20 * rinstHeight - iconSize.h / 2;
          riY =
            data.tunnelCode == "KHR"
              ? y + 30 * rinstHeight + iconSize.h * 2.7
              : y + 30 * rinstHeight - iconSize.h / 2;
          rinstY = y + 30 * rinstHeight;
        } else {
          rcY = y - 30 * rinstHeight;
          riY = y - 30 * rinstHeight - iconSize.h / 2;
          rinstY = y - 30 * rinstHeight;
        }

        eg.select(".cameraId")
          .attr("x", x + iconLength / 2)
          .attr("y", y + textY);

        eg.select(".detect")
          .attr("cx", x + iconSize.w)
          .attr("cy", y);

        // if (data.tech.indexOf("S") != -1) {
        //   // 사운드 인 경우
        //   var equipBound = data.bound == upBound ? 1 : -1;
        //   var equipHeight = data.instHeight;
        //   eg.select(".equipState").attr("cx", x).attr("cy", y);

        //   eg.select(".detect")
        //     .attr("cx", x + iconSize.w)
        //     .attr("cy", rinstY);

        //   eg.select(".equipImg")
        //     .attr("x", x + iconSize.w / 2)
        //     .attr("y", riY);
        // } else if (data.tech.indexOf("R") != -1) {
        //   // 레이더 인 경우
        //   var cameraIndex = data.cameraIndex;
        //   var radarBound = data.bound == upBound ? 1 : -1;
        //   var radarHeight = data.instHeight;

        //   eg.select(".equipState")
        //     .attr("cx", x - iconSize.w / 2)
        //     .attr("cy", y + 20 * radarHeight * radarBound);

        //   eg.select(".detect")
        //     .attr("cx", x + iconSize.w / 2)
        //     .attr("cy", rinstY);

        //   eg.select(".equipImg").attr("x", x).attr("y", riY);
        // } else {
        //   // 카메라 인 경우
        //   var equipBound = data.bound == upBound ? 1 : -1;
        //   var equipHeight = data.instHeight;
        //   eg.select(".equipState").attr("cx", x).attr("cy", y);

        //   eg.select(".detect")
        //     .attr("cx", x + iconSize.w / 2)
        //     .attr("cy", rinstY);

        //   eg.select(".equipImg").attr("x", x).attr("y", riY);
        // }

        if (selectTunnelCode != "DBS") {
          if (data.tech.indexOf("S") != -1) {
            // 사운드 인 경우
            var equipBound = data.bound == upBound ? 1 : -1;
            var equipHeight = data.instHeight;
            eg.select(".equipState").attr("cx", x).attr("cy", y);

            eg.select(".detect")
              .attr("cx", x + iconSize.w)
              .attr("cy", rinstY);

            eg.select(".equipImg")
              .attr("x", x + iconSize.w / 2)
              .attr("y", riY);
          } else if (data.tech.indexOf("R") != -1) {
            // 레이더 인 경우
            var cameraIndex = data.cameraIndex;
            var radarBound = data.bound == upBound ? 1 : -1;
            var radarHeight = data.instHeight;

            eg.select(".equipState")
              .attr("cx", x - iconSize.w / 2)
              .attr("cy", y + 20 * radarHeight * radarBound);

            eg.select(".detect")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", rinstY);

            eg.select(".equipImg").attr("x", x).attr("y", riY);
          } else {
            // 카메라 인 경우
            var equipBound = data.bound == upBound ? 1 : -1;
            var equipHeight = data.instHeight;
            eg.select(".equipState").attr("cx", x).attr("cy", y);

            eg.select(".detect")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", rinstY);

            eg.select(".equipImg").attr("x", x).attr("y", riY);
          }
        } else {
          //도봉지하차도 다시 읽을때 장비
          if (bound == 0) {
            //도로
            rcY = y + 30 * rinstHeight * bound;
            riY = y - 30 * rinstHeight - iconSize.h * 9.5;
            rinstY = y + 30 * rinstHeight;
          } else {
            //진입로
            rcY = y - 30 * rinstHeight;
            riY = y - 30 * rinstHeight - iconSize.h / 2;
            rinstY = y - 30 * rinstHeight;
          }

          if (data.tech.indexOf("S") != -1) {
            // 사운드 인 경우
            var equipBound = data.bound == upBound ? 1 : -1;
            var equipHeight = data.instHeight;
            eg.select(".equipState").attr("cx", x).attr("cy", y);

            eg.select(".detect")
              .attr("cx", x + iconSize.w)
              .attr("cy", rinstY);

            eg.select(".equipImg")
              .attr("x", x + iconSize.w / 2)
              .attr("y", riY);
          } else if (data.tech.indexOf("R") != -1) {
            // 레이더 인 경우
            var cameraIndex = data.cameraIndex;
            var radarBound = data.bound == upBound ? 1 : -1;
            var radarHeight = data.instHeight;

            eg.select(".equipState")
              .attr("cx", x - iconSize.w / 2)
              .attr("cy", y + 20 * radarHeight * radarBound);

            eg.select(".detect")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", rinstY);

            eg.select(".equipImg").attr("x", x).attr("y", riY);
          } else {
            // 카메라 인 경우
            var equipBound = data.bound == upBound ? 1 : -1;
            var equipHeight = data.instHeight;
            eg.select(".equipState").attr("cx", x).attr("cy", y);

            eg.select(".detect")
              .attr("cx", x + iconSize.w / 2)
              .attr("cy", rinstY);

            eg.select(".equipImg").attr("x", x).attr("y", riY);
          }
        }
      });
    },
    hideVehicle: function () {
      var g = this.svg.selectAll(".vehicleGroup");
      g.attr("display", "none");
    },
    showVehicle: function () {
      var g = this.svg.selectAll(".vehicleGroup");
      g.attr("display", "");
    },
    redrawVehicle: function (bound) {
      var g = this.svg.select(".vehicleGroup" + bound);

      if (g.empty()) return;

      var vehicles = g.selectAll(".vehicle" + bound);
      var me = this;

      var scaleLinear = downScaleLinear;
      if (bound == 1) scaleLinear = upScaleLinear;
      // scaleLinear.range([90, this.width - 90]);

      vehicles.each(function (data, index, list) {
        var v = d3.select(this);

        var x = scaleLinear(data.y);
        var y = me.getLaneYFromCoord(data.y, data.lane, bound);

        v.attr("cx", x).attr("cy", y).attr("r", me.vehicleSize);
      });
    },
    updateVehicle: function (bound, vehicleList) {
      // 차량정보 그리는 곳
      if (!this.isShow || vehicleList == null) {
        this.removeVehicle();
        return;
      }

      var g = this.svg.select(".vehicleGroup" + bound);

      if (g.empty())
        g = this.svg
          .append("g")
          .attr("class", "vehicleGroup vehicleGroup" + bound);

      var vehicleList;

      var vehicleJson = {};
      var noMatchVehicle = [];

      // 대시보드 방면 옆 차량 댓수 변경
      if (bound == 1) {
        upVehicleList = vehicleList.length;
        this.redrawBoundSign(1, upVehicleList); // "상,하행 ->" 표시
        setUpCount(upVehicleList); // 차량 댓수
      } else {
        downVehicleList = vehicleList.length;
        this.redrawBoundSign(0, downVehicleList);
        setDownCount(downVehicleList); // 차량 댓수
      }

      // 주행 차량 배열을 JSON 형태로 변환
      for (var i = 0; i < vehicleList.length; i++) {
        var vehicle = vehicleList[i];
        vehicleJson[vehicle.id] = vehicle;
      }

      var vehicles = g.selectAll(".vehicle" + bound);
      // var vehicleSize = this.vehicleSize
      var vehicleSize = this.vehicleSize * 0.8;

      // 해당 변수 사용하는건지?
      // var laneNode =
      //   bound == 1
      //     ? this.upLanePath.select(".dash").node()
      //     : this.downLanePath.select(".dash").node();

      var me = this;

      var scaleLinear = downScaleLinear;
      if (bound == 1) {
        scaleLinear = upScaleLinear; // 차량 출발 위치 변경
      }

      scaleLinear.range([20, this.width - 20]);

      var startTime = new Date().getTime();
      //터널별 차량 y 설정
      var tunnelVehicleY = this.tunnelVehicleY;
      var roadLength = this.roadLength;

      //시스템 설정의 범례설정 기준으로 차량속도 색상 표현
      var settingSpeedColor = [];
      settingSpeedColor[0] = this.smooth;
      settingSpeedColor[1] = this.slow;
      settingSpeedColor[2] = this.delay;

      vehicles.each(function (data, index, list) {
        var v = d3.select(this);
        var id = v.attr("id");

        if (vehicleJson.hasOwnProperty(id)) {
          var vehicleData = vehicleJson[id];

          if (isFinite(vehicleData.y) && !isNaN(vehicleData.y)) {
            // var x = scaleLinear(vehicleData.y);
            var x = scaleLinear((vehicleData.y - roadLength) * tunnelVehicleY);
            var y = me.getLaneYFromCoord(
              vehicleData.y,
              vehicleData.lane,
              bound
            );

            v.datum(vehicleData)
              .attr("fill", function (data) {
                if (data.eventType >= 1 && data.eventType <= 3) {
                  return "url(#" + getVehicleColor(0) + me.code + ")";
                } else {
                  return (
                    "url(#" +
                    getVehicleColor(data.speed, settingSpeedColor) +
                    me.code +
                    ")"
                  );
                }
              })
              // .transition()
              // .duration(95)
              .attr("cx", x)
              .attr("cy", y);

            delete vehicleJson[id];
          }
        } else {
          noMatchVehicle.push(v);
        }
      });

      //console.log("checkTime : ", new Date().getTime() - startTime);

      for (var i = 0; i < noMatchVehicle.length; i++) {
        noMatchVehicle[i].remove();
      }

      var strokeWidth = vehicleSize * 0.2;

      for (var id in vehicleJson) {
        var vehicleData = vehicleJson[id];
        var x = scaleLinear(vehicleData.y);
        if (isFinite(vehicleData.y) && !isNaN(vehicleData.y)) {
          // var x = scaleLinear((vehicleData.y - roadLength) * tunnelVehicleY);

          // Y 값을 계산하고 확인합니다.
          var cyValue = this.getLaneYFromCoord(vehicleData.y, vehicleData.lane, bound);
    
          // cy 값이 유효한지 확인
          if (!isFinite(cyValue) || isNaN(cyValue)) {
              // console.error("Invalid cy value for vehicle id:", id, "cy:", cyValue);
              continue;  // 무효한 cy 값이 있으면 해당 차량은 처리하지 않음
          }

          g.append("circle")
            .datum(vehicleData)
            .attr("id", id)
            .attr("class", "vehicle vehicle" + bound)
            .attr("cx", x)
            .attr(
              "cy",
              cyValue
            )
            // .attr("r", vehicleSize* (2 / 3))
            .attr("r", vehicleSize)
            // .attr('stroke', '#494C51')
            // .attr('stroke-width', strokeWidth)
            .attr("fill", function (data) {
              if (data.eventType >= 1 && data.eventType <= 3) {
                return "url(#" + getVehicleColor(0) + me.code + ")";
              } else {
                return (
                  "url(#" +
                  getVehicleColor(data.speed, settingSpeedColor) +
                  me.code +
                  ")"
                );
              }
            });
        }
      }

      vehicleJson = null;
      noMatchVehicle = null;
      vehicles = null;
    },
    removeVehicle: function () {
      var g = this.svg.selectAll(".vehicleGroup");
      if (g.empty()) return;
      g.selectAll(".vehicle").remove();
    },

    // 돌발 발생 지점 circle 표시
    occurEvent: function (data) {
      // var returnequip = this.equipList.find(
      //   (equip) => equip.equipId == data.CameraID
      // );

      // var insHeight = returnequip.instHeight;
      if (!this.isRender) return;

      // var me = this;
      var eventGroup = this.svg.select(".eventGroup");

      if (eventGroup.empty())
        eventGroup = this.svg.append("g").attr("class", "eventGroup");

      var bound = Number(data.bound) == 0 ? 0 : 1;
      var eventIconSize = this.eventSize;
      var eventIconUrl = this.iconConfig.eventImgUrl[data.DEventType];

      var scaleLinear = d3
        .scaleLinear()
        .domain([this.startPosition, this.endPosition])
        .range([0, this.width]);

      var laneNode =
        bound == 1
          ? this.upLanePath.select(".dash").node()
          : this.downLanePath.select(".dash").node();

      var x; // 가로 표시 좌표
      var y; // 세로 표시 좌표

      var eventVehicleY;
      var sbtSdrPosition = data.Position % 1;

      //터널별 차량 y 설정
      switch (this.selectTunnelCode) {
        case "DBS": //도봉지하차도
          eventVehicleY = 1.72;
          break;
        case "SBT": //수락방음터널
          // if (Math.floor(data.Position) == 6) {
          //   sbtSdrPosition = 1 + sbtSdrPosition;
          // }
          // data.Position = sbtSdrPosition.toFixed(2);
          // eventVehicleY = 1.1;
          eventVehicleY = 1.1;
          break;
        case "SDR": //상도지하차도
          // data.Position = sbtSdrPosition.toFixed(2);
          // eventVehicleY = 1.95 * data.Position;
          eventVehicleY = 3.2;
          break;
        case "GRR": //구룡지하차도
          eventVehicleY = 1.98;
          break;
        case "YKR": //염곡동서지하차도
          eventVehicleY = 2.98;
          break;
        case "KHR": //금하지하차도
          eventVehicleY = 1.53;
          break;
      }

      if (bound == 0) {
        // 하행
        // x = scaleLinear(data.Position * eventVehicleY);
        x =
          this.selectTunnelCode == "DBS"
            ? scaleLinear(data.Position / eventVehicleY)
            : scaleLinear(data.Position * eventVehicleY);
        y =
          this.selectTunnelCode == "KHR"
            ? this.getLaneYFromNode(laneNode, x, null, bound) * 1.1
            : this.getLaneYFromNode(laneNode, x, null, bound);
        // y = this.selectTunnelCode == "KHR" ? y*1.11 : 1;
      } else {
        // 상행
        // 상행은 시작이 endposition이 되야함
        // x = scaleLinear(this.endPosition - data.Position * eventVehicleY);
        x = scaleLinear(data.Position * eventVehicleY);
        y = this.getLaneYFromNode(laneNode, x, null, bound);
      }

      var id = getRandomId(10);

      var g = eventGroup
        .append("g")
        .attr("id", "event" + id)
        .attr("class", "event")
        .datum(data);
      //.on("mouseover", function(data, index, paths) { me.event.equipMouseover(data, me.tooltip); })
      //.on("mouseout", function(data, index, paths) { me.event.equipMouseout(data, me.tooltip); });

      /* console.log(data.CameraID);
			var camera = data.CameraID;
			var radar_pos = me.radarPos[camera];
			console.log(radar_pos); */

      // 돌발 검지 위치 circle 그리기
      // var detectVehicle = g
      //   .append("circle")
      //   .attr("class", "detect")
      //   // .attr("cx", data.bound == 0 ? x*eventVehicleY : x/eventVehicleY)
      //   .attr("cx", x)
      //   .attr("cy", y)
      //   .attr("r", eventIconSize * 1.3)
      //   .attr("stroke", "#FC525E63")
      //   .attr("fill", "#FC525E17");

      // g.append("circle")
      //   .attr("class", "emphasis")
      //   .attr("cx", x)
      //   .attr("cy", y)
      //   .attr("r", eventIconSize * 1.2)
      //   .attr("fill", "url(#equipEmphasis" + this.code + ")");

      // g.append("circle")
      //   .attr("class", "detectIn")
      //   .attr("cx", x)
      //   .attr("cy", y)
      //   .attr("r", eventIconSize * 1.0)
      //   .attr("stroke", "#FC525E63")
      //   .attr("fill", "#FC525E17");

      // g.append("svg:image")
      //   .attr("class", "eventImg")
      //   .attr("x", x - eventIconSize / 2)
      //   .attr("y", y - eventIconSize / 2)
      //   .attr("width", eventIconSize)
      //   .attr("height", eventIconSize)
      //   .attr("opacity", 1)
      //   .attr("xlink:href", eventIconUrl);

      //진입로 장비 확인 및 x,y 값 지정
      var slipRoadEquip = false;
      var slipRoadEquipX; //작아질수록 왼쪽으로 움직임
      var slipRoadEquipY = y * 1.34; //작아질수록 위로 올라감
      if (data.CameraID == "DBS-SND-E-00223") {
        //음원#24
        slipRoadEquip = true;
        slipRoadEquipX = x * 1.05;
      } else if (data.CameraID == "DBS-SND-E-00243") {
        //음원#26
        slipRoadEquip = true;
        slipRoadEquipX = x * 0.985;
      } else if (data.CameraID == "DBS-CAM-A-10000") {
        //CAM#31
        slipRoadEquip = true;
        slipRoadEquipX = x * 0.97;
      } else if (data.CameraID == "DBS-CAM-A-10100") {
        //CAM#32
        slipRoadEquip = true;
        slipRoadEquipX = x * 0.95;
      } else if (data.CameraID == "DBS-CAM-A-11250") {
        //CAM#33
        slipRoadEquip = true;
        slipRoadEquipX = x * 0.95;
      }

      var linkCode = data.CameraID.substring(0, 3);

      if (linkCode != "DBS") {
        // 돌발 검지 위치 circle 그리기
        var detectVehicle = g
          .append("circle")
          .attr("class", "detect")
          // .attr("cx", data.bound == 0 ? x*eventVehicleY : x/eventVehicleY)
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.3)
          .attr("stroke", "#FC525E63")
          .attr("fill", "#FC525E17");

        g.append("circle")
          .attr("class", "emphasis")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.2)
          .attr("fill", "url(#equipEmphasis" + this.code + ")");

        g.append("circle")
          .attr("class", "detectIn")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.0)
          .attr("stroke", "#FC525E63")
          .attr("fill", "#FC525E17");

        g.append("svg:image")
          .attr("class", "eventImg")
          .attr("x", x - eventIconSize / 2)
          .attr("y", y - eventIconSize / 2)
          .attr("width", eventIconSize)
          .attr("height", eventIconSize)
          .attr("opacity", 1)
          .attr("xlink:href", eventIconUrl);
      } else {
        // 돌발 검지 위치 circle 그리기
        var detectVehicle = g
          .append("circle")
          .attr("class", "detect")
          // .attr("cx", data.bound == 0 ? x*eventVehicleY : x/eventVehicleY)
          .attr("cx", slipRoadEquip == false ? x : slipRoadEquipX)
          .attr("cy", slipRoadEquip == false ? y / 1.15 : slipRoadEquipY)
          .attr("r", eventIconSize * 1.3)
          .attr("stroke", "#FC525E63")
          .attr("fill", "#FC525E17");

        g.append("circle")
          .attr("class", "emphasis")
          .attr("cx", slipRoadEquip == false ? x : slipRoadEquipX)
          .attr("cy", slipRoadEquip == false ? y / 1.15 : slipRoadEquipY)
          .attr("r", eventIconSize * 1.2)
          .attr("fill", "url(#equipEmphasis" + this.code + ")");

        g.append("circle")
          .attr("class", "detectIn")
          .attr("cx", slipRoadEquip == false ? x : slipRoadEquipX)
          .attr("cy", slipRoadEquip == false ? y / 1.15 : slipRoadEquipY)
          .attr("r", eventIconSize * 1.0)
          .attr("stroke", "#FC525E63")
          .attr("fill", "#FC525E17");

        g.append("svg:image")
          .attr("class", "eventImg")
          .attr(
            "x",
            slipRoadEquip == false
              ? x - eventIconSize / 2
              : slipRoadEquipX - eventIconSize / 2
          )
          .attr(
            "y",
            slipRoadEquip == false
              ? (y - eventIconSize / 2) / 1.15
              : slipRoadEquipY - eventIconSize / 2
          )
          .attr("width", eventIconSize)
          .attr("height", eventIconSize)
          .attr("opacity", 1)
          .attr("xlink:href", eventIconUrl);
      }

      /* g.append("rect")
			.attr("class","tooltip")
			.attr('x' , x-(eventIconSize*2))
			.attr('y' , y-(eventIconSize*2))
			.attr("width", eventIconSize*4)
			.attr("height", eventIconSize*1)
			.attr("stroke", "#181D2C")
			.attr("fill","#181D2C"); */

      //console.log(this.isEquipRender);

      //if(this.isEquipRender) {

      var cameraId = data.CameraID.replace("(", "\\(")
        .replace(")", "\\)")
        .replace("/", "\\/");
      var iconSize = this.iconConfig.iconSize;

      var equip = this.svg.select(".equipGroup").select("#camera" + cameraId);

      // g.raise();
      // detectVehicle.raise();

      //	console.log(equip);

      // 돌발 발생 중 터널탭 클릭시 감지한 돌발리스트 초기화
      EventBus.$on("tunnelChkVal", (tunnelChkVal) => {
        if (tunnelChkVal == true) {
          this.detectList = {};
        }
      });

      if (!equip.empty()) {
        this.eventList[id] = { cameraId: cameraId };
        var radarImgUrl = this.iconConfig.radarImgUrl;
        var soundImgUrl = this.iconConfig.soundImgUrl;
        // var detectEquip = equip.select(".detect");
        var detectEquip =
          cameraId.indexOf("RDR") != -1
            ? equip.select(".detect")
            : equip.select(".detect");
        var upBound = this.upBound;
        if (
          !this.detectList.hasOwnProperty(cameraId) ||
          this.detectList[cameraId] == 0
        ) {
          this.detectList[cameraId] = 1;

          //if(data.tech == null){
          //	equip.select(".equipImg")
          //	.attr("xlink:href", function(data) { return soundImgUrl.detect[data.bound == upBound? 0 : 1] });
          //}else{

          /* var imgNum = this.radarImgDir[cameraId];
							equip.select(".equipImg")
							.attr("xlink:href", function(data) { return radarImgUrl.detect[imgNum] }); */

          //}

          //}

          //equip.select(".equipImg")
          //.attr("xlink:href", function(data) { return radarImgUrl.detect[data.bound == upBound? 0 : 1] });

          detectEquip.style("display", "");
        } else {
          this.detectList[cameraId]++;
        }
        // if(cameraId.indexOf('RDR')== -1){
        var x1 = detectEquip.attr("cx") * 1;
        var y1 = detectEquip.attr("cy") * 1;
        var r1 = detectEquip.attr("r") * 1;
        var x2 = detectVehicle.attr("cx") * 1;
        var y2 = detectVehicle.attr("cy") * 1;
        var r2 = detectVehicle.attr("r") * 1;

        var theta = Math.atan2(x2 - x1, y2 - y1);

        var link = [
          [x1 + r1 * Math.sin(theta), y1 + r1 * Math.cos(theta)],
          [x2 - r2 * Math.sin(theta), y2 - r2 * Math.cos(theta)],
        ];
        var line = d3.line();
        g.append("path")
          .datum(link)
          .attr("class", "link")
          .attr("d", line)
          .attr("stroke", "#E64359")
          .attr("stroke-dasharray", "2,2")
          .attr("stroke-width", 1 / 1);
        // }
        /* var line = d3.line();

					g.append("path")
					.datum(link)
					.attr("class","link")
					.attr("d", line)
					.attr("stroke", "#E64359")
					.attr('stroke-dasharray',"2,2")
					.attr("stroke-width", 1); */
      }
      //}
      setTimeout(() => {
        this.removeEvent(id);
        // }, 100000);
      }, 10000);
    },
    redrawEvent: function () {
      var me = this;
      var eventGroup = this.svg.select(".eventGroup");
      var equipGroup = this.svg.select(".equipGroup");

      if (eventGroup.empty()) return;

      var eventList = eventGroup.selectAll(".event");
      var eventIconSize = this.eventSize;

      var scaleLinear = d3
        .scaleLinear()
        .domain([this.startPosition, this.endPosition])
        .range([0, this.width]);

      var upLaneNode = this.upLanePath.select(".dash").node();
      var downLaneNode = this.downLanePath.select(".dash").node();

      eventList.each(function (data) {
        var g = d3.select(this);

        var bound = data.bound == 1 ? 1 : 0;
        //var bound = data.bound == 2 ? 0 : 1;
        var laneNode = bound == 1 ? upLaneNode : downLaneNode;

        var x; // 가로 표시 좌표
        var y; // 세로 표시 좌표
        if (bound == 0) {
          // 하행
          x = scaleLinear(data.Position);
          y = me.getLaneYFromNode(laneNode, x, null, bound);
        } else {
          // 상행
          // 상행은 시작이 endposition이 되야함
          x = scaleLinear(me.endPosition - data.Position);
          y = me.getLaneYFromNode(laneNode, x, null, bound);
        }

        // var x = scaleLinear(data.Position);
        // var y = me.getLaneYFromNode(laneNode, x, null, bound);

        var detectVehicle = g
          .select(".detect")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.3);

        g.select(".emphasis")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.2);

        g.select(".detectIn")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", eventIconSize * 1.0);

        g.select(".eventImg")
          .attr("x", x - eventIconSize / 2)
          .attr("y", y - eventIconSize / 2)
          .attr("width", eventIconSize)
          .attr("height", eventIconSize);

        // eventGroup.raise();
        // detectVehicle.raise();

        var link = g.select(".link");

        if (!link.empty() && !equipGroup.empty()) {
          var cameraId = data.CameraID.replace("(", "\\(")
            .replace(")", "\\)")
            .replace("/", "\\/");

          var equip = equipGroup.select("#camera" + cameraId);

          if (!equip.empty()) {
            var detectEquip = equip.select(".detect");

            var x1 = detectEquip.attr("cx") * 1;
            var y1 = detectEquip.attr("cy") * 1;
            var r1 = detectEquip.attr("r") * 1;
            var x2 = detectVehicle.attr("cx") * 1;
            var y2 = detectVehicle.attr("cy") * 1;
            var r2 = detectVehicle.attr("r") * 1;

            var theta = Math.atan2(x2 - x1, y2 - y1);

            var linkData = [
              [x1 + r1 * Math.sin(theta), y1 + r1 * Math.cos(theta)],
              [x2 - r2 * Math.sin(theta), y2 - r2 * Math.cos(theta)],
            ];

            var line = d3.line();

            link.datum(linkData).attr("d", line);
          }
        }
      });
    },
    updateEquipState: function (data, tmp) {
      var equipIconUrl = this.iconConfig.radarImgUrl.normal;
      var equipIconUrlDetect = this.iconConfig.radarImgUrl.detect;

      var cameraIconUrl = this.iconConfig.cameraImgUrl.normal;
      var cameraIconUrlDetect = this.iconConfig.cameraImgUrl.error;

      var soundIconUrl = this.iconConfig.soundImgUrl.normal;
      var soundIconUrlDetect = this.iconConfig.soundImgUrl.error;

      var yugoCameraIconUrl = this.iconConfig.yugoCameraImgUrl.normal;
      var yugoCameraIconUrlError = this.iconConfig.yugoCameraImgUrl.error;

      if (!this.isRender || !this.isEquipRender) return false;
      var type = data.type;
      var cameraId = data.id
        .trim()
        .replace("(", "\\(")
        .replace(")", "\\)")
        .replace("/", "\\/");
      var bound = tmp[cameraId] == "1" ? 0 : 1;

      var equip = this.svg.select(".equipGroup").select("#camera" + cameraId);

      if (!equip.empty()) {
        var status = data.state == 0 ? "equipNormal" : "equipError";

        if (type == "S") {
          equip
            .select(".equipImg")
            .attr(
              "xlink:href",
              status == "equipNormal"
                ? soundIconUrl[bound]
                : soundIconUrlDetect[bound]
            );
        } else if (type == "R") {
          equip
            .select(".equipImg")
            .attr(
              "xlink:href",
              status == "equipNormal"
                ? equipIconUrl[bound]
                : equipIconUrlDetect[bound]
            );
        } else if (type == "C") {
          equip.select(".equipImg").attr(
            "xlink:href",
            data.yugoDetectorYn == "N"
              ? cameraIconUrl[way]
              : state == "equipNormal"
              ? yugoCameraIconUrl[way]
              : yugoCameraIconUrlError[way]
            // cameraIconUrl[way]
          );
          // .attr(
          //   "xlink:href",
          //   status == "equipNormal"
          //     ? cameraIconUrl[bound]
          //     : cameraIconUrlDetect[bound]
          // );
        } else {
        }
      } else {
        return false;
      }
    },
    removeEvent: function (id) {
      var eventGroup = this.svg.select(".eventGroup");
      if (eventGroup.empty()) return;

      var g = eventGroup.select("#event" + id);
      if (g.empty()) return;

      g.remove();

      //if(!this.isEquipRender || this.eventList[id] == null) return;
      if (this.eventList[id] == null) return;

      var cameraId = this.eventList[id].cameraId;
      var upBound = this.upBound;

      //console.log(cameraId);

      if (--this.detectList[cameraId] == 0) {
        //var radarImgUrl = this.iconConfig.radarImgUrl;
        //var soundImgUrl = this.iconConfig.soundImgUrl;
        var equip = this.svg.select(".equipGroup").select("#camera" + cameraId);

        //if(cameraId.indexOf('IDS') != -1){
        //	equip.select(".equipImg")
        //	.attr("xlink:href", function(data) { return soundImgUrl.normal[data.bound == upBound? 0 : 1] });
        //}
        //else{
        //	var imgNum = this.radarImgDir[cameraId];
        //	equip.select(".equipImg")
        //	.attr("xlink:href", function(data) { return radarImgUrl.normal[imgNum] });
        //}

        equip.select(".detect").style("display", "none");
      }

      delete this.eventList[id];
    },
    viewCamera: function (id) {
      var cameraImgUrl = this.iconConfig.cameraImgUrl;
      var yugoCameraImgUrl = this.iconConfig.yugoCameraImgUrl;
      var equipGroup = this.svg.select(".equipGroup");
      var upBound = this.upBound;

      //var iconSize = this.iconConfig.iconSize;
      //var scale = 1.2;

      //var moveX = ((iconSize.w * scale) - iconSize.w)/2;
      //var moveY = ((iconSize.w * scale) - iconSize.w)/2;

      // 이전에 보여주던 카메라 아이콘 원래대로
      if (this.viewCameraId != null) {
        var beforeCameraId = this.viewCameraId
          .replace("(", "\\(")
          .replace(")", "\\)")
          .replace("/", "\\/");
        var beaforeEquip = equipGroup.select("#camera" + beforeCameraId);

        if (!beaforeEquip.empty()) {
          if (this.isJaeyaksan) {
            if (this.selectTunnelCode != "DBS") {
              beaforeEquip
                .select(".equipImg")
                //.attr("x",function(){ return this.x.baseVal.value + moveX; })
                //.attr("y",function(){ return this.y.baseVal.value + moveY; })
                //.attr("width", iconSize.w)
                //.attr("height", iconSize.h)
                .attr("xlink:href", function (data) {
                  if (data.state == "0") {
                    return data.yugoDetectorYn == "Y"
                      ? yugoCameraImgUrl.normal[
                          data.direction != upBound ? 0 : 1
                        ]
                      : cameraImgUrl.normal[data.direction != upBound ? 0 : 1];
                  } else {
                    return data.yugoDetectorYn == "Y"
                      ? yugoCameraImgUrl.error[
                          data.direction != upBound ? 0 : 1
                        ]
                      : cameraImgUrl.error[data.direction != upBound ? 0 : 1];
                  }
                  return cameraImgUrl.normal[data.direction != upBound ? 0 : 1];
                });
            } else {
              beaforeEquip
                .select(".equipImg")
                .attr("xlink:href", function (data) {
                  if (data.state == "0") {
                    return data.yugoDetectorYn == "Y"
                      ? yugoCameraImgUrl.normal[1]
                      : cameraImgUrl.normal[1];
                  } else {
                    return data.yugoDetectorYn == "Y"
                      ? yugoCameraImgUrl.error[1]
                      : cameraImgUrl.error[1];
                  }
                });
            }
          } else {
            beaforeEquip.select(".viewCamera").style("display", "none");
          }
        }
      }

      this.viewCameraId = id;

      var cameraId = id
        .replace("(", "\\(")
        .replace(")", "\\)")
        .replace("/", "\\/");
      var equip = equipGroup.select("#camera" + cameraId);

      if (!equip.empty()) {
        if (this.isJaeyaksan) {
          if (this.selectTunnelCode != "DBS") {
            equip
              .select(".equipImg")
              //.attr("x",function(){ return this.x.baseVal.value - moveX; })
              //.attr("y",function(){ return this.y.baseVal.value - moveY; })
              //.attr("width", iconSize.w * scale)
              //.attr("height", iconSize.h * scale)
              .attr("xlink:href", function (data) {
                // console.log("data:::",data.yugoDetectorYn);
                if (data.yugoDetectorYn == "Y") {
                  return yugoCameraImgUrl.view[
                    data.direction != upBound ? 0 : 1
                  ];
                }
                {
                  return cameraImgUrl.view[data.direction != upBound ? 0 : 1];
                }
                return data.yugoDetectorYn == "Y"
                  ? yugoCameraImgUrl.view[data.direction != upBound ? 0 : 1]
                  : cameraImgUrl.view[data.direction != upBound ? 0 : 1];
                // return cameraImgUrl.view[data.direction != upBound ? 0 : 1];
              });
          } else {
            equip.select(".equipImg").attr("xlink:href", function (data) {
              if (data.yugoDetectorYn == "Y") {
                return yugoCameraImgUrl.view[1];
              }
              {
                return cameraImgUrl.view[1];
              }
              return data.yugoDetectorYn == "Y"
                ? yugoCameraImgUrl.view[data.direction != upBound ? 0 : 1]
                : cameraImgUrl.view[data.direction != upBound ? 0 : 1];
            });
          }
        } else {
          equip.select(".viewCamera").style("display", "");
        }
      }
    },
    getLaneData: function (startX, startY, width, height) {
      return [
        { x: startX, y: startY },
        { x: startX + width, y: startY },
      ];
    },
    getLaneYFromCoord: function (nonScaleX, lane, bound) {
      if (nonScaleX == null) return;

      var y = 0;
      var x = parseInt(nonScaleX);

      if (this.selectTunnelCode != "DBS") {
        // 0: 하행 , 1: 상행
        if (bound == 1) {
          var upVehicleY = null;
          var upLaneCnt = this.selectLaneCnt.up;

          //상행 차선별 개별차량 y값
          if (upLaneCnt == 1) {
            upVehicleY = 0.93;
          }
          if (upLaneCnt == 2) {
            lane == 0 ? (upVehicleY = 0.865) : (upVehicleY = 1.185);
          }
          if (upLaneCnt == 3) {
            if (lane == 0) {
              upVehicleY = 0.85;
            } else if (lane == 1) {
              upVehicleY = 1.08;
            } else if (lane == 2) {
              upVehicleY = 1.21;
            }
          }
          if (upLaneCnt == 4) {
            if (lane == 0) {
              upVehicleY = 0.84;
            } else if (lane == 1) {
              upVehicleY = 1.06;
            } else if (lane == 2) {
              upVehicleY = 1.12;
            } else if (lane == 3) {
              upVehicleY = 1.23;
            }
          }
          if (upLaneCnt == 5) {
            if (lane == 0) {
              upVehicleY = 0.83;
            } else if (lane == 1) {
              upVehicleY = 1.01;
            } else if (lane == 2) {
              upVehicleY = 1.065;
            } else if (lane == 3) {
              upVehicleY = 1.155;
            } else if (lane == 4) {
              upVehicleY = 1.25;
            }
          }

          if (x < 0) y = upPointList[0];
          else if (x > upPointList.length - 1)
            y = upPointList[upPointList.length - 1] / upVehicleY;
          else y = upPointList[x] / upVehicleY;
        } else {
          var downVehicleY = null;
          var downLaneCnt = this.selectLaneCnt.down;

          //하행 차선별 개별차량 y값
          if (downLaneCnt == 1) {
            downVehicleY = 0.95;
          }
          if (downLaneCnt == 2) {
            lane == 0 ? (downVehicleY = 0.905) : (downVehicleY = 1.095);
          }
          if (downLaneCnt == 3) {
            if (lane == 0) {
              downVehicleY = 0.89;
            } else if (lane == 1) {
              downVehicleY = 1.055;
            } else if (lane == 2) {
              downVehicleY = 1.115;
            }
          }
          if (downLaneCnt == 4) {
            if (lane == 0) {
              downVehicleY = 0.88;
            } else if (lane == 1) {
              downVehicleY = 1.02;
            } else if (lane == 2) {
              downVehicleY = 1.07;
            } else if (lane == 3) {
              downVehicleY = 1.12;
            }
          }
          // if (downLaneCnt == 5) {
          //   if (lane == 0) {
          //     downVehicleY = 0.87;
          //   } else if (lane == 1) {
          //     downVehicleY = 1.01;
          //   } else if (lane == 2) {
          //     downVehicleY = 1.045;
          //   } else if (lane == 3) {
          //     downVehicleY = 1.09;
          //   } else if (lane == 4) {
          //     downVehicleY = 1.125;
          //   }
          // }
          if (downLaneCnt == 5) {
            if (lane == 0) {
              downVehicleY = 0.89;
            } else if (lane == 1) {
              downVehicleY = 1.075;
            } else if (lane == 2) {
              downVehicleY = 1.175;
            } else if (lane == 3) {
              downVehicleY = 1.28;
            } else if (lane == 4) {
              downVehicleY = 1.375;
            }
          }

          if (x < 0) y = downPointList[0];
          else if (x > downPointList.length - 1)
            y = downPointList[downPointList.length - 1] * downVehicleY;
          else y = downPointList[x] * downVehicleY;
        }
      } else {
        var dbsDwnVehicleY = null;
        // 도봉지차하도 1차선 차량 높이
        if (lane == 0) {
          dbsDwnVehicleY = 0.605;
          // 도봉지차하도 2차선 차량 높이
        } else if (lane == 1) {
          dbsDwnVehicleY = 0.855;
          // 도봉지차하도 3차선 차량 높이
        } else if (lane == 2) {
          dbsDwnVehicleY = 1.015;
        }

        if (x < 0) y = downPointList[0];
        else if (x > downPointList.length - 1)
          y = downPointList[downPointList.length - 1] * dbsDwnVehicleY;
        else y = downPointList[x] * dbsDwnVehicleY;
      }

      return this.getLaneY(y, lane, bound);
    },
    getLaneYFromNode: function (laneNode, x, lane, bound) {
      if (laneNode == null || x == null) return;

      var y = laneNode.getPointAtLength(x).y / 0.97;

      return this.getLaneY(y, lane, bound);
    },
    getLaneY: function (y, lane, bound) {
      var laneHeight = this.laneHeight;

      var boundY = bound == 0 ? 1 : -1;

      if (lane != null) {
        if (lane == 0) {
          y = y + (laneHeight / 4) * boundY;
        } else {
          y = y - (laneHeight / 4) * boundY;
        }
      }

      return y;
    },
    event: {
      equipClick: function () {},
      equipMouseover: function (data, index, tooltip) {
        tooltip
          .style("visibility", "visible")
          .style("left", d3.event.pageX - 20 + "px")
          .style("top", d3.event.pageY - 30 + "px")
          .style("width", "auto")
          .transition()
          .duration(200)
          .text(data.name)
          .on("start", function () {
            // transition 시작 시점에 실행될 함수
            // 텍스트의 넓이 계산
            var textWidth = tooltip.node().getBoundingClientRect().width;
            // 계산된 넓이로 업데이트
            tooltip.style("width", textWidth + "px");
          });
        /* if(tooltip != null && tooltip.class != null) {

					tooltip.data.data = data;

					if(tooltip.div == null)
						tooltip.div = d3.select("."+tooltip.class);

					tooltip.div
					.style("left", (d3.event.pageX) + "px")
					.style("top", (d3.event.pageY) + "px")
					.transition()
					.duration(200)
					.style("opacity", 9);
				} */
      },
      equipMouseout: function (data, index, tooltip) {
        tooltip.style("visibility", "hidden");
      },

      scheduleMouseover: function (
        event,
        schedule,
        linearStartP,
        laneY,
        linearWidth,
        scheduleTooltip
      ) {
        const scheduleTooltipGroup = d3.select(".scheduleTooltipGroup");
        scheduleTooltipGroup.selectAll("rect").remove();
        scheduleTooltipGroup.selectAll("image").remove();
        scheduleTooltipGroup.style("visibility", "visible");

        let minusY = null;
        let rectMinusY = null;
        let rectMinusX = null;
        let imgMinusY = null;
        let imgMinusX = null;
        let textMinusY = null;
        let textMinusX = null;
        let circleSize = 30;
        let imgSize = 20;
        // console.log("🚀 ~ event:", event)
        // console.log("🚀 ~ event:", event.target.getBBox());
        // console.log("🚀 ~ schedule:", schedule)

        // const bbox = event.target.getBBox();
        // const centerX = bbox.x + bbox.width / 2;
        // const centerY = bbox.y;
        // console.log("🚀 ~ Center Coordinates: ", { centerX, centerY });

        var textWidth = scheduleTooltipGroup
          .node()
          .getBoundingClientRect().width;
        // var textWidth = scheduleTooltip.node().getBoundingClientRect().width;

        var tunnelTypeMinusY = 0;
        switch (schedule.tunnelCode) {
          case "DBS": {
            tunnelTypeMinusY = +75;
            break;
          }
          case "KHR": {
            tunnelTypeMinusY = +60;
            break;
          }
          default:
            tunnelTypeMinusY = +50;
            break;
        }

        if (schedule.bound == 0) {
          minusY = tunnelTypeMinusY;
          rectMinusY = -15;
          rectMinusX = -60;
          imgMinusX = -50;
          imgMinusY = 0;
          textMinusX = 0;
          textMinusY = 5;
        } else {
          minusY = -90;
          rectMinusY = -10;
          rectMinusX = -55;
          imgMinusX = -45;
          imgMinusY = 10;
          textMinusX = 0;
          textMinusY = 10;
        }

        // scheduleTooltipGroup.append("image")
        //   .attr("x", linearStartP + (linearWidth / 2 ) - (textWidth / 2) + rectMinusX ) // x 위치 설정
        //   .attr("y", laneY + minusY + rectMinusY)
        //   .attr("width", 30 + "px")  // 이미지의 너비
        //   .attr("height", 30 + "px") // 이미지의 높이
        //   .attr("href", "/resources/img/svg/construction.svg")
        //   .style("background-color", "rgb(249,213,61)")
        //   .style("border-radius"," 24px")

        // .html(
        //   "<div>"
        //  <img src='/resources/img/svg/construction.svg' alt='Construction' style='background:rgb(249,213,61); width:30px; height:30px; border-radius: 24px; display:flex;'/>"
        //   + "</div>"

        // )

        scheduleTooltipGroup
          .insert("rect", "text")
          .attr(
            "x",
            linearStartP + linearWidth / 2 - textWidth / 2 + rectMinusX
          ) // x 위치 설정
          .attr("y", laneY + minusY + rectMinusY)
          .attr("rx", 5) // 둥근 모서리
          .attr("ry", 5)
          .attr("fill", "#181D2C");

        // .style("background-color", "#");

        const textLines = [
          // "no : " + schedule.no,
          // "일정명: " + schedule.scheduleName,
          // "시작이정: " + schedule.startPosition * 1000 + "m",
          // "종료이정: " + schedule.endPosition * 1000 + "m",
          i18n.t("label.L0072") + ": " + schedule.scheduleName,
          i18n.t("label.L0073") + ": " + schedule.startPosition * 1000 + "m",
          i18n.t("label.L0074") + ": " + schedule.endPosition * 1000 + "m",
        ];
        // 기존 텍스트 제거
        scheduleTooltip.selectAll("tspan").remove();
        const textElement = scheduleTooltip
          // .attr("x", linearStartP + (linearWidth / 2 ) - (textWidth / 2))
          // .attr("x", bbox.x )
          // .attr("y", bbox.y)
          // .style("visibility", "visible")
          .attr("y", laneY + minusY + textMinusY)
          // .style("left", centerX + "px")
          // .style("top", (centerY * 2 ) + "px")
          // .style("left", "50%")
          // .style("top", "50%")
          // .style("left", linearStartP * 2 + "px")
          // .style("top", laneY + 150 + "px")
          // .style("left", d3.event.pageX - 50 + "px")
          // .style("top", d3.event.pageY - 85 + "px")
          .style("text-align", "center")
          .style("width", "auto")
          .style("height", "auto")
          .style("background-color", "white")

          .transition()
          .duration(200)
          // .text("no : " + schedule.no + "\n name: " + schedule.scheduleName)
          .on("start", function () {
            // 텍스트의 넓이 계산
            var textWidth = scheduleTooltip
              .node()
              .getBoundingClientRect().width;
            var textHeight = scheduleTooltip
              .node()
              .getBoundingClientRect().height;
            // 계산된 넓이로 업데이트
            // scheduleTooltip.style("width", textWidth + "px");
            // scheduleTooltip.style("height", textHeight + "px");
          });

        textLines.forEach(function (line, index) {
          scheduleTooltip
            .append("tspan")
            .attr("x", linearStartP + linearWidth / 2 - textWidth / 2) // x 위치 설정
            .attr("fill", "white")
            .attr("dy", index === 0 ? "0em" : "1.2em") // 첫 번째 줄은 기본, 그 이후 줄은 다음 줄로 이동
            .text(line);
        });

        //circle
        scheduleTooltipGroup
          .append("rect")
          .attr("x", linearStartP + linearWidth / 2 - textWidth / 2 + imgMinusX) // x 위치 설정
          .attr("y", laneY + minusY + imgMinusY)
          .attr("width", circleSize + "px") // 이미지의 너비
          .attr("height", circleSize + "px") // 이미지의 높이
          .attr("fill", "rgb(249,213,61)")
          .attr("rx", 15) // 둥근 모서리
          .attr("ry", 15);

        // img
        scheduleTooltipGroup
          .append("image")
          .attr(
            "x",
            linearStartP +
              linearWidth / 2 -
              textWidth / 2 +
              imgMinusX +
              (circleSize - imgSize) / 2
          ) // x 위치 설정
          .attr("y", laneY + minusY + imgMinusY + 5)
          .attr("width", imgSize + "px") // 이미지의 너비
          .attr("height", imgSize + "px") // 이미지의 높이
          .attr("href", "/resources/img/svg/construction.svg");

        scheduleTooltipGroup
          .select("rect")
          .attr(
            "width",
            scheduleTooltipGroup.node().getBoundingClientRect().width + 15
          )
          .attr(
            "height",
            scheduleTooltipGroup.node().getBoundingClientRect().height + 15
          );

        // d3.select(".equipGroup").lower();
        // d3.select(".eventGroup").raise();
        // let roadG = d3.selectAll(".road")
        // roadG.raise();

        //   		width: 15px;
        // height: 15px;
        // vertical-align:unset !important;
        // margin-top: 4px;
      },
      scheduleMouseout: function () {
        const scheduleTooltipGroup = d3.select(".scheduleTooltipGroup");
        // const scheduleTooltipGroup = d3.select(".scheduleTooltipGroup");
        scheduleTooltipGroup.style("visibility", "hidden");
      },
    },

    setZIndex: function (element, zIndex) {
      if (zIndex === "front") {
        element.raise();
      } else if (zIndex === "back") {
        element.lower();
      } else {
        console.error("Invalid zIndex value");
      }
    },
    selectDrawLane: function (val, startX, upStartY, downStartY) {
      //상행
      if (val.length > 1) {
        var upBound = val[1].bound;
      }
      //하행
      var downBound = val[0].bound;

      //기존 점선 삭제
      if (val.length > 1) {
        var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
        upLaneGroup.selectAll(".dash").remove();
      }
      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".dash").remove();

      if (val == null) {
        var val = this.selectTunnelInfo;
        var startX = 0;
        var upStartY = this.selectUpLaneY;
        var downStartY = this.selectDownLaneY;
      }

      //해상도 기준의 너비
      var width = this.width;

      //상행 y
      if (val.length > 1) {
        var upY = upStartY;
      }
      //하행 y
      var downY = downStartY;

      //상행
      if (val.length > 1) {
        var upBound = val[1].bound;
      }
      //하행
      var downBound = val[0].bound;

      //상행 차선 수
      if (val.length > 1) {
        var upLaneCnt = val[1].lanecnt;
      }
      //하행 차선 수
      var downLaneCnt = val[0].lanecnt;

      //상행그룹
      if (val.length > 1) {
        var upLaneGroup = this.svg
          .select(".road")
          .select(".laneGroup" + upBound);
      }
      //하행그룹
      var downLaneGroup = this.svg
        .select(".road")
        .select(".laneGroup" + downBound);

      //도봉지하차도 제외한 하행 도로 보이게 설정
      upLaneGroup.style("display", "");

      if (val.length > 1) {
        //차선에 따른 위치 조절
        if (upBound == 1 && upLaneCnt > 1) {
          for (var i = 0; i < upLaneCnt; i++) {
            //상행 2차선
            if (upLaneCnt == 2) {
              upY = upStartY;
            }

            //상행 3차선
            if (upLaneCnt == 3) {
              if (i == 1) {
                upY = upStartY / 1.05;
              }
              if (i == 2) {
                upY = upStartY / 0.95;
              }
            }

            //상행 4차선
            if (upLaneCnt == 4) {
              if (i == 1) {
                upY = upStartY / 1.08;
              }
              if (i == 2) {
                upY = upStartY;
              }
              if (i == 3) {
                upY = upStartY / 0.93;
              }
            }

            //상행 5차선
            if (upLaneCnt == 5) {
              if (i == 1) {
                upY = upStartY / 1.09;
              }
              if (i == 2) {
                upY = upStartY / 1.02;
              }
              if (i == 3) {
                upY = upStartY / 0.96;
              }
              if (i == 4) {
                upY = upStartY / 0.91;
              }
            }

            //상행 중앙 점선
            var upDash = this.getLaneData(
              startX + 8,
              upY,
              width - 16,
              this.roadHeight
            );

            // 상행 점선
            if (i > 0) {
              upLaneGroup
                .append("path")
                .attr("class", "dash")
                .attr("d", this.curveFunc(upDash))
                .attr("stroke", "#727A8E")
                .attr("stroke-dasharray", "5,13")
                .attr("fill", "none");
            }
          }
        }
      }

      // 금하지하차도 일 경우 도로 제거 후 재생성
      var khrVal = val;
      var khrCode = khrVal[0].code;
      var downBound = khrVal[0].bound;
      var laneHeight = this.laneHeight;
      var laneBorderWidth = this.laneBorderWidth;
      var pathCenterY = 0;

      // if(khrCode=="KHR"){
      var downLaneY = this.downLaneY - pathCenterY;
      var khrLaneX = khrCode == "KHR" ? 2 : 1;
      var khrLaneY = khrCode == "KHR" ? 1.175 : 1;
      var lane = this.getLaneData(
        startX * khrLaneX,
        downLaneY * khrLaneY,
        width,
        this.roadHeight
      );
      var khrDownLaneY = khrCode == "KHR" ? 35 : 5;
      var laneBorderW = khrCode == "KHR" ? 75 : 11;
      var roadShoulderW = khrCode == "KHR" ? 74 : 10;

      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".lane").remove();

      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".laneBorder").remove();

      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".roadShoulder").remove();

      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".laneShoulder").remove();

      var downLaneGroup = this.svg.selectAll(".laneGroup" + downBound);
      downLaneGroup.selectAll(".dash").remove();

      downLaneGroup
        .append("path")
        .attr("class", "laneBorder")
        .attr(
          "d",
          this.curveFunc(
            this.getLaneData(
              startX,
              downLaneY + khrDownLaneY,
              width,
              this.roadHeight
            )
          )
        )
        .attr("stroke", "#727A8E")
        .attr("stroke-width", laneHeight + laneBorderW)
        .attr("fill", "none");

      downLaneGroup
        .append("path")
        .attr("class", "roadShoulder")
        .attr(
          "d",
          this.curveFunc(
            this.getLaneData(
              startX,
              downLaneY + khrDownLaneY,
              width,
              this.roadHeight
            )
          )
        )
        .attr("stroke", "#323232")
        .attr("stroke-width", laneHeight + roadShoulderW)
        .attr("fill", "none");

      downLaneGroup
        .append("path")
        .attr("class", "laneShoulder")
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#727A8E")
        .attr("stroke-width", laneHeight + laneBorderWidth * 2)
        .attr("fill", "none")
        .style("display", khrCode == "KHR" ? "none" : "");

      // 실선
      downLaneGroup
        .append("path")
        .attr("class", "lane")
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#323232")
        .attr("stroke-width", laneHeight)
        .attr("fill", "none");
      // }

      if (downBound == 0 && downLaneCnt > 1) {
        for (var i = 0; i < downLaneCnt; i++) {
          //하행 2차선
          if (downLaneCnt == 2) {
            downY = downStartY;
          }

          //하행 3차선
          if (downLaneCnt == 3) {
            if (i == 1) {
              downY = downStartY / 1.03;
            }
            if (i == 2) {
              downY = downStartY / 0.96;
            }
          }

          //하행 4차선
          if (downLaneCnt == 4) {
            if (i == 1) {
              downY = downStartY / 1.05;
            }
            if (i == 2) {
              downY = downStartY;
            }
            if (i == 3) {
              downY = downStartY / 0.95;
            }
          }

          //하행 5차선
          // if (downLaneCnt == 5) {
          //   if (i == 1) {
          //     downY = downStartY / 1.02;
          //   }
          //   if (i == 2) {
          //     downY = downStartY / 1.06;
          //   }
          //   if (i == 3) {
          //     downY = downStartY / 0.98;
          //   }
          //   if (i == 4) {
          //     downY = downStartY / 0.94;
          //   }
          // }

          //하행 5차선
          if (downLaneCnt == 5) {
            if (i == 0) {
              downY = downStartY / 1.02;
            }
            if (i == 1) {
              downY = downStartY / 0.93;
            }
            if (i == 2) {
              downY = downStartY / 0.85;
            }
            if (i == 3) {
              downY = downStartY / 0.78;
            }
            if (i == 4) {
              downY = downStartY / 0.73;
            }
          }

          // 금하지하차도 일직 방면 도로 점선 관련 수정
          // if (this.selectTunnelCode == "KHR") {
          //   if (i == 0) {
          //     startX = 445;
          //   }
          //   if (i == 1) {
          //     startX = 0;
          //   }
          //   if (i == 2) {
          //     startX = 1540;
          //   }
          //   if (i == 3) {
          //     startX = 1540;
          //   }
          //   if (i == 4) {
          //     startX = 0;
          //   }
          // }

          //하행 중앙 점선
          var downDash = this.getLaneData(
            // startX + 8,
            // downY,
            // width - 16,
            // this.roadHeight
            khrCode == "KHR" && i == 4 ? startX : startX + 8,
            downY,
            khrCode == "KHR" && i == 4 ? width : width - 16,
            this.roadHeight
          );

          // if (i > 0) {
          if (khrCode == "KHR" ? i >= 0 : i > 0) {
            // 하행 점선
            downLaneGroup
              .append("path")
              .attr("class", "dash")
              .attr("d", this.curveFunc(downDash))
              .attr("stroke", "#727A8E")
              // .attr("stroke-dasharray", "5,13")
              .attr(
                khrCode == "KHR" && i == 4
                  ? "stroke-width"
                  : "stroke-dasharray",
                khrCode == "KHR" && i == 4 ? "1.5" : "5,13"
              )
              .attr("fill", "none");
          }
        }
      }
    },

    // 공사이정 그리기
    drawSchedule: function (schedule) {
      // // 활동중인 공사이정에서 하행 클래스를 가진 요소 선택
      // let downScheduleGroup = document.querySelectorAll('.scheduleGroup0');

      // // 각 요소에 대해 반복
      // downScheduleGroup.forEach(element => {
      //     // 자식 요소가 없는 경우 부모 요소 삭제
      //     if (element.children.length === 0) {
      //         element.remove();
      //     }
      // });

      // // 활동중인 공사이정에서 상행 클래스를 가진 요소 선택
      // let upScheduleGroup = document.querySelectorAll('.scheduleGroup1');

      // // 각 요소에 대해 반복
      // upScheduleGroup.forEach(element => {
      //     // 자식 요소가 없는 경우 부모 요소 삭제
      //     if (element.children.length === 0) {
      //         element.remove();
      //     }
      // });

      var me = this;
      var width = this.width;
      var roadHeight = this.roadHeight;
      var laneHeight = this.laneHeight;
      var laneBorderWidth = this.laneBorderWidth;
      var startPosition = this.scheduleStartPosition;
      var endPosition = this.scheduleEndPosition;
      let laneY = 0;
      let imagelaneY = 0;

      if (schedule.bound == 1) {
        laneY = schedule.tunnelCode == "DBS" ? this.downLaneY : this.upLaneY;
      } else {
        laneY = schedule.tunnelCode == "DBS" ? this.upLaneY : this.downLaneY;
      }

      let scheduleStartP = schedule.startPosition;
      let scheduleEndP = schedule.endPosition;

      let drawScheduleWidth = scheduleEndP - scheduleStartP;
      var svgRoad = this.svg;

      // 선형변환 함수 제작 (화면 너비로 변경)
      var scaleLinear = d3
        .scaleLinear()
        .domain([startPosition, endPosition])
        .range([0, this.width]);

      //도봉지하차도, 금하지하차도(하행)
      var dbsKhrDownTf = false;
      if (this.selectTunnelCode == "DBS") {
        dbsKhrDownTf = true;
        laneY = laneY * 1.285;
        imagelaneY = laneY * 0.915;
      }
      if (this.selectTunnelCode == "KHR" && schedule.bound == 0) {
        dbsKhrDownTf = true;
        laneY = laneY * 1.13;
        imagelaneY = laneY * 0.915;
      }

      // 선형변환 값
      var linearStartP = scaleLinear(scheduleStartP);
      var linearWidth = scaleLinear(drawScheduleWidth);
      var lane = this.getLaneData(
        linearStartP,
        laneY,
        linearWidth,
        this.roadHeight
      );

      var scheduleGroup = svgRoad
        .append("g")
        .attr("class", "scheduleGroup" + schedule.bound);
      let scheduleTooltipGroup = d3.select(".scheduleTooltipGroup");
      let scheduleTooltip = d3.select(".scheduleTooltip");

      scheduleGroup.raise();
      d3.select(".scheduleTooltipGroup").raise();

      if (!scheduleTooltipGroup.node()) {
        // scheduleTooltip 추가
        scheduleTooltip = svgRoad
          .append("g")
          .attr("class", "scheduleTooltipGroup")
          .style("visibility", "hidden")
          .append("text")
          .attr("class", "scheduleTooltip")
          .style("height", "20px")
          // .style("background-color", "#181D2C")
          .style("text-color", "white")
          .style("text-align", "left")
          .style("border-radius", "3px")
          .style("border-width", "2px")
          .style("font-size", "10pt")
          .style("position", "absolute")
          // .style("display", "flex")
          .style("min-width", "max-content");
        // .style("display", "inline-block")
        // .style("visibility", "hidden");
        // scheduleTooltip = d3
        //   .select("body")
        //   .append("div")
        //   .attr("class", "scheduleTooltip")
        //   .style("height", "20px")
        //   .style("background-color", "#181D2C")
        //   .style("color", "white")
        //   .style("text-align", "left")
        //   .style("border-radius", "3px")
        //   .style("border-width", "2px")
        //   .style("font-size", "10pt")
        //   .style("position", "absolute")
        //   // .style("display", "flex")
        //   .style("min-width", "max-content")
        //   .style("z-index", "10")
        //   .style("visibility", "hidden");
      }
      // var laneHeight = this.laneHeight *3.5 -50;
      // var lane = this.getLaneData(linearStartP, laneY*1.285, linearWidth, this.roadHeight);

      scheduleGroup
        .append("path")
        .attr("class", "construction" + schedule.no)
        .attr("id", "construction" + schedule.no)
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#F9D53D")
        .attr(
          "stroke-width",
          dbsKhrDownTf == false ? laneHeight : laneHeight * 3.5 - 50
        )
        // .attr("fill", "url('/resources/img/svg/diagonal-line.svg') no-repeat")
        // .attr("fill", "none")
        .attr("opacity", 0.4)
        .attr(
          "title",
          "construction" +
            schedule.no +
            ", scheduleName : " +
            schedule.scheduleName
        );

      scheduleGroup
        .on("mouseover", function () {
          // console.log("🚀 ~ .on ~ bbox :", this.getBBox() )
          me.event.scheduleMouseover(
            d3.event,
            schedule,
            linearStartP,
            laneY,
            linearWidth,
            scheduleTooltip
          );
        })
        .on("mouseout", function () {
          me.event.scheduleMouseout();
        });

      scheduleGroup
        .append("svg:image")
        .attr("xlink:href", "/resources/img/svg/construction.svg")
        .attr("class", "constructionImg")
        .attr("id", "constructionImg" + schedule.no)
        .attr("x", linearStartP)
        .attr("y", dbsKhrDownTf == false ? laneY - 15 : imagelaneY)
        .attr("width", dbsKhrDownTf == false ? linearWidth - 5 : linearWidth)
        .attr(
          "height",
          dbsKhrDownTf == false ? laneHeight - 20 : (laneHeight - 20) * 1.5
        )
        .attr("opacity", 1);

      // var equips = g
      //   .selectAll(null)
      //   .data(this.equipList)
      //   .enter()
      //   .append("g")
      //   .on("click", function (data, index, paths) {
      //     if (data.tech == "C") me.event.equipClick(data, index);
      //   })
      //   .on("mouseover", function (data, index, paths) {
      //     me.event.equipMouseover(data, index, tooltip);
      //   })
      //   .on("mouseout", function (data, index, paths) {
      //     me.event.equipMouseout(data, index, tooltip);
      //   });

      return scheduleGroup;
    },

    showTooltip: function (title, x, y) {
      const tooltip = d3.select(".tooltip");
      tooltip.html(title);
      tooltip
        .style("left", x + 10 + "px")
        .style("top", y + 10 + "px")
        .style("display", "block");
    },
    selectdrawSignPost: function (val, startX, upStartY, downStartY) {
      //기존 이정 삭제
      this.svg.selectAll(".centerPath").remove();
      this.svg.selectAll(".signPost").remove();
      this.svg.selectAll(".position").remove();

      var width = this.width;
      var height = this.height;

      var roadHeight = this.roadHeight;

      var pathCenterY = 0;

      var centerLane = this.getLaneData(
        0,
        height / 2 - pathCenterY,
        width,
        roadHeight
      );

      var g = this.svg.select(".road");

      //centerLane 생성
      var centerPath = g
        .append("path")
        .attr("class", "centerPath")
        .attr("d", this.curveFunc(centerLane))
        .attr("fill", "none");

      this.centerPath = centerPath;

      //하행 시작 이정
      var startposition = val[0].startposition;
      //하행 종료 이정
      var endposition = val[0].endposition / 100;

      //상행 길이
      // var upStartX = getLoadConfigJson.upStartX;
      // var upEndX = getLoadConfigJson.upEndX;
      // // 하행 길이
      // var downStartX = getLoadConfigJson.downStartX;
      // var downEndX = getLoadConfigJson.downEndX;
      // 길이 나누는 수
      // var signpostCount = getLoadConfigJson.signpostCount;
      // 나누는 단위 0.1 => 100
      // var signpostDiv = getLoadConfigJson.signpostDiv;

      var signpostCount = endposition * 10 + 1;

      //signPost 생성
      var signPostG = g.append("g").attr("class", "signPost");

      var signpostPath = signPostG
        .append("path")
        .attr("class", "signPostPath")
        .attr("d", this.curveFunc(centerLane))
        .attr("stroke", "#9E9E9E")
        .attr("fill", "none");

      // var startPosition = this.startPosition;
      // var endPosition = this.endPosition;
      var signpostLinear = d3
        .scaleLinear()
        .domain([0, this.width])
        .range([startposition, endposition]);

      var scaleLinear = d3
        .scaleLinear()
        .domain([startposition, endposition])
        .range([0, this.width]);

      var drawStartPosition = signpostLinear(0 + 40);
      var drawEndPosition = signpostLinear(this.width - 40);

      drawStartPosition = parseFloat(drawStartPosition.toFixed(1));
      drawEndPosition = parseFloat(drawEndPosition.toFixed(1));

      for (var i = 0; i < signpostCount; i++) {
        var position = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var positionText = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var x = scaleLinear(position);
        var y = signpostPath.node().getPointAtLength(x).y;
        var positionGroup = g
          .append("g")
          .datum(position)
          .attr("class", "position");

        positionGroup
          .append("rect")
          .attr("x", x - 50 / 2)
          .attr("y", y - 20 / 2)
          .attr("width", 50)
          .attr("height", 20)
          .attr("fill", "#2C2C2C");

        // if (this.selectTunnelCode == "SBT") {
        //   positionText = positionText + 5.0;
        //   positionText = positionText.toFixed(1);
        // } else if (this.selectTunnelCode == "SDR") {
        //   positionText = positionText + 6.4;
        //   positionText = positionText.toFixed(1);
        // }

        positionGroup
          .append("svg:text")
          .text(positionText * 1000 + "m")
          .attr("x", x)
          .attr("y", y)
          .attr("width", 50)
          .attr("height", 20)
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle")
          .attr("fill", "#848484")
          .attr("font-size", "1rem")
          .attr("font-family", "'NotoSansKR',Open Sans,sans-serif");
      }
    },
    initTunnel: function (val) {
      this.initTunnelInfo = val;
    },
    drawDriveWayLine: function (roadGroup, startX, bound) {
      var g = roadGroup.append("g").attr("class", "driveWayLine");

      var roadHeight = this.roadHeight + 30;
      var roadPadding = this.roadPadding;
      var centerPath = this.centerPath;

      var signY = roadHeight + roadPadding;

      var y =
        centerPath.node().getPointAtLength(startX).y +
        (bound == 1 ? -signY : signY);
      var boxHeight = 30;

      //A-line
      var ALineY = 1.61;

      g.append("rect")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 8)
        .attr("y", (y + 2) / ALineY - boxHeight / 1.5)
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("width", 80)
        .attr("height", boxHeight / 1.5)
        .attr("fill-opacity", 0.5)
        .attr("fill", "#2c2c2c")
        .style("display", "none");

      g.append("svg:text")
        .html("A-line")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 7.78)
        .attr("y", (y + 2) / ALineY / 1.035)
        .attr("width", 100)
        .attr("height", boxHeight)
        .attr("dominant-baseline", "middle")
        .attr("text-anchor", "middle")
        //  .attr("fill", "#2c2c2c")
        .attr("font-size", "1.25rem")
        .attr("font-family", "'NotoSansKR',Open Sans,sans-serif")
        .style("display", "none");

      //B-line
      var BLineY = 2.6;

      g.append("rect")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 8)
        .attr("y", (y + 2) / BLineY - boxHeight / 1.8)
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("width", 80)
        .attr("height", boxHeight * 1.3)
        // .attr("fill", "#E1E1E1")
        .attr("fill-opacity", 0.5)
        .attr("fill", "#2c2c2c")
        .style("display", "none");

      g.append("svg:text")
        .html("B-line")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 7.78)
        .attr("y", (y + 2) / BLineY / 0.98)
        .attr("width", 100)
        .attr("height", boxHeight)
        .attr("dominant-baseline", "middle")
        .attr("text-anchor", "middle")
        // .attr("fill", "#2c2c2c")
        .attr("font-size", "1.5rem")
        .attr("font-family", "'NotoSansKR',Open Sans,sans-serif")
        .style("display", "none");

      //C-line
      var CLineY = 1.5;

      g.append("rect")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 8)
        .attr("y", (y + 2) / CLineY + boxHeight * 0.25)
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("width", 80)
        .attr("height", boxHeight / 1.5)
        .attr("fill-opacity", 0.5)
        .attr("fill", "#2c2c2c")
        .style("display", "none");

      g.append("svg:text")
        .html("C-line")
        .attr("class", "driveWayLineA")
        .attr("x", this.width - 190 * 7.78)
        .attr("y", ((y + 2) / CLineY) * 1.08)
        .attr("width", 100)
        .attr("height", boxHeight)
        .attr("dominant-baseline", "middle")
        .attr("text-anchor", "middle")
        //  .attr("fill", "#2c2c2c")
        .attr("font-size", "1.25rem")
        .attr("font-family", "'NotoSansKR',Open Sans,sans-serif")
        .style("display", "none");

      // //C-line
      // var CLineY = 1.3;

      // g
      // .append("rect")
      // .attr("class","driveWayLineA")
      // .attr("x", this.width - 190 * 2.22)
      // .attr("y", (y + 2)/CLineY - boxHeight / 2)
      // .attr("rx", 5)
      // .attr("ry", 5)
      // .attr("width", 80)
      // .attr("height", boxHeight)
      // // .attr("fill", "#E1E1E1")
      // .attr("fill-opacity", 0.5)
      // .attr("fill", "#2c2c2c")
      // .style("display", "none");
      // g
      // .append("svg:text")
      // .html(
      //   "C-line"
      // )
      // .attr("class","driveWayLineA")
      // .attr("x", this.width - 190 * 2)
      // .attr("y", (y + 2)/CLineY)
      // .attr("width", 100)
      // .attr("height", boxHeight)
      // .attr("dominant-baseline", "middle")
      // .attr("text-anchor", "middle")
      // // .attr("fill", "#2c2c2c")
      // .attr("font-size", "1.5rem")
      // .attr("font-family", "'NotoSansKR',Open Sans,sans-serif")
      // .style("display", "none");
    },
    dbsSelectDrawLane: function (val, startX, upStartY) {
      //하행
      var upBound = val[0].bound;

      //기존 점선 삭제
      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".dash").remove();
      //upLaneGroup.style("display", "none");
      var downLaneGroup = this.svg.selectAll(".laneGroup1");
      downLaneGroup.selectAll(".dash").remove();
      //도봉지하차도 하행 도로 안 보이게 설정
      downLaneGroup.style("display", "none");

      if (val == null) {
        var val = this.selectTunnelInfo;
        var startX = 0;
        var upStartY = this.selectDownLaneY;
      }

      //해상도 기준의 너비
      var width = this.width;

      //하행그룹
      var upLaneGroup = this.svg.select(".road").select(".laneGroup" + upBound);

      var laneHeight = this.laneHeight * 3.5;
      var laneBorderWidth = this.laneBorderWidth;
      var pathCenterY = 0;
      var upLaneY = this.upLaneY - pathCenterY;
      var laneBorderW = 11;
      var roadShoulderW = 10;

      var lane = this.getLaneData(startX, upLaneY, width, this.roadHeight);
      //upStartY 클수록 라인이 아래로 내려감
      var dash = this.getLaneData(
        startX,
        upStartY * 1.44,
        width,
        this.roadHeight
      );
      //진입로 앞의 윗 부분 계산식
      var slipRoad_fu = this.getLaneData(
        startX,
        upStartY * 1.54,
        width / 2.35,
        this.roadHeight
      );

      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".lane").remove();

      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".laneBorder").remove();

      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".roadShoulder").remove();

      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".laneShoulder").remove();

      var upLaneGroup = this.svg.selectAll(".laneGroup" + upBound);
      upLaneGroup.selectAll(".dash").remove();

      //전체 도로 폭
      upLaneGroup
        .append("path")
        .attr("class", "lane")
        .attr("d", this.curveFunc(lane))
        .attr("stroke", "#323232")
        .attr("stroke-width", laneHeight - 50) // 숫자를 내릴수록 폭이 올라감
        .attr("fill", "none")
        .attr("transform", "translate(0,40)"); // 숫자를 내릴수록 폭이 좁아짐

      //1,2 차선 사이 점선 부분
      upLaneGroup
        .append("path")
        .attr("class", "dash")
        .attr("d", this.curveFunc(dash))
        .attr("stroke", "#727A8E")
        .attr("stroke-dasharray", "5,13")
        .attr("fill", "none")
        .attr("transform", "translate(0," + -laneHeight / 4 + ")"); // 숫자를 늘릴수록 중심선하고 가까워짐

      //2,3 차선 점선
      upLaneGroup
        .append("path")
        .attr("class", "dash")
        .attr("d", this.curveFunc(dash))
        .attr("stroke", "#727A8E")
        .attr("stroke-dasharray", "5,13")
        .attr("fill", "none")
        .attr("transform", "translate(0,-" + this.laneHeight / 12 + ")"); // 숫자를 늘릴수록 중심선하고 가까워짐

      //상단 라인 부분
      upLaneGroup
        .append("path")
        .attr("class", "laneShoulder")
        .attr("d", this.curveFunc(dash))
        .attr("stroke", "#727A8E")
        .attr("fill", "none")
        .attr("transform", "translate(0," + -laneHeight / 2 + ")")
        .attr("stroke-width", laneBorderWidth * 5); // 숫자를 늘릴수록 회색이 두꺼워짐

      //진입로 앞의 윗 부분의 길이
      var pathData_fu = this.curveFunc(slipRoad_fu);
      var indexOfComma = pathData_fu.indexOf(",");
      //d3 진입로 높이를 맞추기 위한 기존 d3 높이값 가져옴
      var extractedSubstring = pathData_fu.substring(indexOfComma + 1, 6);

      pathData_fu +=
        "L770,244,L630,244,L630,284,L810,284,L860," +
        extractedSubstring +
        ",L1875," +
        extractedSubstring;

      //진입로 앞의 윗 표현
      upLaneGroup
        .append("path")
        .attr("class", "dash")
        // .attr("d", pathData_fu)
        .attr(
          "d",
          this.curveFunc(
            this.getLaneData(startX, upLaneY * 1.5, width, this.roadHeight)
          )
        )
        .attr("stroke", "#727A8E") // 선의 색상 설정
        .attr("fill", "none")
        .attr("transform", "translate(0," + laneHeight / 8.5 + ")")
        .attr("stroke-width", laneBorderWidth * 5);

      // upLaneGroup
      //   .append("path")
      //   .attr("class", "laneBorder")
      //   .attr(
      //     "d",
      //     this.curveFunc(
      //       this.getLaneData(
      //         startX,
      //         upLaneY,
      //         width,
      //         this.roadHeight
      //       )
      //     )
      //   )
      //   .attr("stroke", "#727A8E")
      //   .attr("stroke-width", laneHeight + laneBorderW)
      //   .attr("fill", "none");

      // upLaneGroup
      // .append("path")
      // .attr("class", "roadShoulder")
      // .attr(
      //   "d",
      //   this.curveFunc(
      //     this.getLaneData(
      //       startX,
      //       upLaneY,
      //       width,
      //       this.roadHeight
      //     )
      //   )
      // )
      // .attr("stroke", "#323232")
      // .attr("stroke-width", laneHeight + roadShoulderW)
      // .attr("fill", "none");

      // upLaneGroup
      // .append("path")
      // .attr("class", "laneShoulder")
      // .attr("d", this.curveFunc(lane))
      // .attr("stroke", "#727A8E")
      // .attr("stroke-width", laneHeight + laneBorderWidth * 2)
      // .attr("fill", "none");

      // // 실선
      // upLaneGroup
      //   .append("path")
      //   .attr("class", "lane")
      //   .attr("d", this.curveFunc(lane))
      //   .attr("stroke", "#323232")
      //   .attr("stroke-width", laneHeight)
      //   .attr("fill", "none");
    },
    dbsSelectdrawSignPost: function (val, startX, downStartY) {
      //기존 이정 삭제
      this.svg.selectAll(".centerPath").remove();
      this.svg.selectAll(".signPost").remove();
      this.svg.selectAll(".position").remove();

      var width = this.width;
      var height = this.height;

      var roadHeight = this.roadHeight;

      var pathCenterY = 0;

      var centerLane = this.getLaneData(
        0,
        height / 3.3 - pathCenterY,
        width,
        roadHeight
      );

      var g = this.svg.select(".road");

      //하행 시작 이정
      var startposition = val[0].startposition;
      //하행 종료 이정
      var endposition = val[0].endposition / 100;

      var signpostCount = endposition * 10 + 1;

      //signPost 생성
      var signPostG = g.append("g").attr("class", "signPost");

      var signpostPath = signPostG
        .append("path")
        .attr("class", "signPostPath")
        .attr("d", this.curveFunc(centerLane))
        .attr("stroke", "#9E9E9E")
        .attr("fill", "none");

      var signpostLinear = d3
        .scaleLinear()
        .domain([0, this.width])
        .range([startposition, endposition]);

      var scaleLinear = d3
        .scaleLinear()
        .domain([startposition, endposition])
        .range([0, this.width]);

      var drawStartPosition = signpostLinear(0);
      var drawEndPosition = signpostLinear(this.width);

      drawStartPosition = parseFloat(drawStartPosition.toFixed(1));
      drawEndPosition = parseFloat(drawEndPosition.toFixed(1));

      for (var i = 0; i < signpostCount; i++) {
        var position = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var positionText = parseFloat(
          (drawStartPosition + signpostDiv * i).toFixed(1)
        );
        var x = scaleLinear(position);
        var y = signpostPath.node().getPointAtLength(x).y;
        var positionGroup = g
          .append("g")
          .datum(position)
          .attr("class", "position");

        positionGroup
          .append("rect")
          .attr("x", x - 50 / 2)
          .attr("y", y - 20 / 2)
          .attr("width", 50)
          .attr("height", 20)
          .attr("fill", "#2C2C2C");

        positionGroup
          .append("svg:text")
          .text(positionText * 1000 + "m")
          .attr("x", x)
          .attr("y", y)
          .attr("width", 50)
          .attr("height", 20)
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle")
          .attr("fill", "#848484")
          .attr("font-size", "1rem")
          .attr("font-family", "'NotoSansKR',Open Sans,sans-serif");
      }
    },
    destroy: function () {
      window.removeEventListener("resize", resizeRoadMap);
    },
  };

  function getVehicleColor(speed, settingSpeedColor) {
    // speed = Math.abs(speed);
    // if (speed >= 40) return "vehicleGreen";
    // else if (speed >= 20) return "vehicleYellow";
    // else if (speed >= 3) return "vehicleOrange";
    // else return "vehicleRed";

    // 시스템 설정의 범례설정 기준으로 변경
    speed = Math.abs(speed);
    if (speed >= settingSpeedColor[0]) return "vehicleGreen";
    else if (speed >= settingSpeedColor[1]) return "vehicleYellow";
    else if (speed >= settingSpeedColor[2]) return "vehicleOrange";
    else return "vehicleRed";
  }

  function getRandomId(n) {
    var date = new Date();
    return date.getTime() + getRandomChar(n);
  }

  function getRandomChar(n) {
    var str = "";
    for (var i = 0; i < n; i++) {
      str += String.fromCharCode(Math.floor(Math.random() * 24 + 97));
    }
    return str;
  }

  var resizeRoadMap = function () {
    roadMap.redraw();
  };

  if (option != null) util.mergeJson(roadMap, option);

  roadMap.mapContainerId = roadMap.mapContainerId || "roadMap";

  roadMap.init();

  window.addEventListener("resize", resizeRoadMap);

  return roadMap;
};
