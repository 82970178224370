import {BehaviorSubject, Subject} from 'rxjs'
import {take ,filter } from 'rxjs/operators'
const toggle=new BehaviorSubject(null);

const upData=new BehaviorSubject(null);
const downData=new BehaviorSubject(null);

const openPopup=new BehaviorSubject(null);

export const setOpenPopup=function(value){
    openPopup.next(value);
}
export const getOpenPopup=function(){
    return openPopup.pipe(filter((value)=>value!==null && value !==undefined));
}
export const setToggle=function(value){
    toggle.next(value);
}
export const getToggle=function(){
    return toggle.asObservable();
}

export const setUpCount=function(value){
    upData.next(value);
}
export const getUpCount=function(){
    return upData.pipe(filter((value)=>value!==null && value !==undefined));
}

export const setDownCount=function(value){
    downData.next(value);
}
export const getDownCount=function(){
    return downData.pipe(filter((value)=>value!==null && value !==undefined));
}